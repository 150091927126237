import { useMutation } from "@apollo/client";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CInvalidFeedback,
  CLabel,
  CRow,
  CTextarea
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { API_GET_DOWNLOAD_APPLICATION_ZIP } from "../../../../api";
import { SAVE_APPLICATION_PART5 } from "../../../../graphql/mutations";
import { API_URL } from "../../../../index";

const ReferencesDeclaration = ({
  isDisabled,
  onStepChange,
  applicationData,
}) => {
  const [saveApplicationPart5, { data, loading, called }] = useMutation(
    SAVE_APPLICATION_PART5
  );

  const [referenceDetails, setReferenceDetails] = useState({});

  const [declaration, setDeclaration] = useState(false);

  const [isErrors, setIsErrors] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const validate = (val, name) => {
    if (val.replace(/ /g, "").length === 0) {
      setIsInvalid((isInvalid) => ({
        ...isInvalid,
        [name]: true,
      }));
    } else {
      setIsInvalid((isInvalid) => ({
        ...isInvalid,
        [name]: false,
      }));
    }

    if (name === "emailref1" || name === "emailref2") {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regex.test(val)) {
        setIsInvalid((isInvalid) => ({
          ...isInvalid,
          [name]: true,
        }));
      } else {
        setIsInvalid((isInvalid) => ({
          ...isInvalid,
          [name]: false,
        }));
      }
    }
  };

  const [isInvalid, setIsInvalid] = useState({
    nameref1: "",
    emailref1: "",
    addressref1: "",
    nameref2: "",
    emailref2: "",
    addressref2: "",
  });

  useEffect(() => {
    if (applicationData) {

      let temp_ref = {
        nameref1: applicationData.referee1.name,
        nameref2: applicationData.referee2.name,
        addressref1: applicationData.referee1.address,
        addressref2: applicationData.referee2.address,
        emailref1: applicationData.referee1.email,
        emailref2: applicationData.referee2.email,
      };
      setReferenceDetails(temp_ref);

      setDeclaration(true);
    }
  }, [applicationData]);

  const handleNext = () => {
    let invalid_count = 0;
    let process_flag = true;
    if (
      Object.keys(isInvalid).length < 6 ||
      isInvalid.emailref1 === true ||
      isInvalid.emailref2 === true
    ) {
      setIsErrors(true);
      process_flag = false;
    }
    for (var key in isInvalid) {
      if (isInvalid.hasOwnProperty(key)) {
        if (isInvalid[key] === true) {
          invalid_count++;
          setIsErrors(true);
          process_flag = false;
          break;
        }
      }
    }

    if (invalid_count === 0) {
      process_flag = true;
      setIsErrors(false);
    }

    if (declaration === false) {
      process_flag = false;
    }

    // process_flag=true

    if (process_flag) {
      let referees = {
        referee1: {
          name: referenceDetails.nameref1,
          address: referenceDetails.addressref1,
          email: referenceDetails.emailref1,
        },
        referee2: {
          name: referenceDetails.nameref2,
          address: referenceDetails.addressref2,
          email: referenceDetails.emailref2,
        },
        acceptDeclaration: declaration,
      };

      saveApplicationPart5({ variables: referees })
        .then((result) => {})
        .catch((err) => console.error(err));
    }
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setReferenceDetails((referenceDetails) => ({
      ...referenceDetails,
      [name]: value,
    }));

    validate(value, name);
  };

  const history = useHistory();

  function handleViewRefereeReport1() {
    let win = window.open(
      `viewReferee?id=${applicationData.id}&refNo=1&name=${applicationData.nameinit}&course=${applicationData.pref1.course}`,
      "_blank"
    );
    win.focus();
  }

  function handleViewRefereeReport2() {
    let win = window.open(
      `/viewReferee?id=${applicationData.id}&refNo=2&name=${applicationData.nameinit}&course=${applicationData.pref1.course}`,
      "_blank"
    );
    win.focus();
    //history.push(`/viewReferee?id=${applicationData.id}&refNo=2&name=${applicationData.nameinit}&course=${applicationData.pref1.course}`);
  }

  return (
    <>
      <CCard className="custom-card">
        <CCardHeader className="custom-card-header">
          Reference & Declaration
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="12" xl="12">
              <CFormGroup
                style={{
                  border: `2px solid #d8dbe0`,
                  borderRadius: 15,
                  padding: "15px",
                }}
                className="d-flex-row"
              >
                <span className="h6">NOTE 1:</span>
                <span>
                  {" "}
                  At least one should be from the applicant's teacher at
                  university.
                </span>
                <div className="small mb-2 mt-2 red-text">System generated recommendation forms will be emailed to the referees directly. <br/>
Referee’s response will not be disclosed to the applicant under any circumstance.</div>
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow className="mt-4">
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="presentdesignation" className="h6">
                  Name of Referee 01
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <CInput
                  id="nameref1"
                  name="nameref1"
                  placeholder="Enter Name of Referee 01"
                  required
                  invalid={isInvalid.nameref1}
                  onChange={handleOnChange}
                  disabled={isDisabled}
                  value={referenceDetails.nameref1}
                />
                <CInvalidFeedback>This field cannot be empty!</CInvalidFeedback>
              </CFormGroup>
              <CFormGroup>
                <CLabel htmlFor="emailref1" className="h6">
                  Email of Referee 01
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <CInput
                  type="email"
                  id="emailref1"
                  name="emailref1"
                  placeholder="Enter Email of Referee 01"
                  required
                  invalid={isInvalid.emailref1}
                  onChange={handleOnChange}
                  disabled={isDisabled}
                  value={referenceDetails.emailref1}
                />
                <CInvalidFeedback>Please enter a valid email!</CInvalidFeedback>
              </CFormGroup>
            </CCol>

            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="addressref1" className="h6">
                  Address of Referee 01
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <CTextarea
                  name="addressref1"
                  id="addressref1"
                  rows="5"
                  placeholder="Enter Address of Referee 01"
                  required
                  invalid={isInvalid.addressref1}
                  onChange={handleOnChange}
                  disabled={isDisabled}
                  value={referenceDetails.addressref1}
                />
                <CInvalidFeedback>This field cannot be empty!</CInvalidFeedback>
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CButton
                onClick={handleViewRefereeReport1}
                disabled={!applicationData.refereeReport1Submitted}
                size="md"
                color="primary"
              >
                View Referee Report 01
              </CButton>
            </CCol>
          </CRow>
          <CRow className="mt-4 mb-4">
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="nameref2" className="h6">
                  Name of Referee 02
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <CInput
                  id="nameref2"
                  name="nameref2"
                  placeholder="Enter Name of Referee 02"
                  required
                  invalid={isInvalid.nameref2}
                  onChange={handleOnChange}
                  disabled={isDisabled}
                  value={referenceDetails.nameref2}
                />
                <CInvalidFeedback>This field cannot be empty!</CInvalidFeedback>
              </CFormGroup>
              <CFormGroup>
                <CLabel htmlFor="emailref2" className="h6">
                  Email of Referee 02
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <CInput
                  type="email"
                  id="emailref2"
                  name="emailref2"
                  placeholder="Enter Email of Referee 02"
                  required
                  invalid={isInvalid.emailref2}
                  onChange={handleOnChange}
                  disabled={isDisabled}
                  value={referenceDetails.emailref2}
                />
                <CInvalidFeedback>Please enter a valid email!</CInvalidFeedback>
              </CFormGroup>
            </CCol>

            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="addressref2" className="h6">
                  Address of Referee 02
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <CTextarea
                  name="addressref2"
                  id="addressref2"
                  rows="5"
                  placeholder="Enter Address of Referee 02"
                  required
                  invalid={isInvalid.addressref2}
                  onChange={handleOnChange}
                  disabled={isDisabled}
                  value={referenceDetails.addressref2}
                />

                <CInvalidFeedback>This field cannot be empty!</CInvalidFeedback>
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <CButton
                onClick={handleViewRefereeReport2}
                size="md"
                disabled={!applicationData.refereeReport2Submitted}
                color="primary"
              >
                View Referee Report 02
              </CButton>
            </CCol>
          </CRow>

          <CRow className="mt-4 mb-4">
            <CCol>
              <CFormGroup variant="custom-checkbox" inline>
                <CInputCheckbox
                  custom
                  id="declaration"
                  name="declaration"
                  onClick={() => setDeclaration(!declaration)}
                  disabled={isDisabled}
                  checked={declaration}
                />
                <CLabel
                  variant="custom-checkbox"
                  htmlFor="declaration"
                  className="text-justify"
                >
                  I do hereby declare that particulars provided by me in this
                  application are true and accurate to the best of my knowledge,
                  and that I am not registered for any degree programme in any
                  University in Sri Lanka at the time of this application. In
                  the event of me being selected for a programme of study I have
                  applied for, I hereby agree to abide by such Rules and
                  Regulations of the Institute as are applicable to me.
                </CLabel>
              </CFormGroup>
            </CCol>
          </CRow>
          <div
            className={isErrors ? "small mb-2" : "d-sm-none mb-2"}
            style={{ color: "#ab0202" }}
          >
            * Please fill out all compulsory fields!
          </div>
          <div
            className={!declaration ? "small mb-2" : "d-sm-none mb-2"}
            style={{ color: "#ab0202" }}
          >
            * Please accept the declaration!
          </div>
          <hr />
          <CRow>
            <CCol xs="12" md="12" xl="12" className="d-flex flex-row-reverse">
              <CButton
                type="submit"
                size="md"
                color="primary"
                onClick={() => onStepChange(4)}
              >
                <CIcon name="cil-arrow-left" /> &nbsp;&nbsp;Back
              </CButton>
              {applicationData ? (
                <CButton
                  size="md"
                  color="info"
                  style={{ minWidth: 180, marginRight: 15 }}
                  target="_blank"
                  href={`${API_URL}/${API_GET_DOWNLOAD_APPLICATION_ZIP}/${applicationData.id}`}
                >
                  Download Application Package&nbsp;&nbsp;
                  <CIcon name="cil-cloud-download" />
                </CButton>
              ) : null}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

export default ReferencesDeclaration;
