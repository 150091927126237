import { Redirect, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { TheLayoutAdmin, TheLayoutApplicant, TheLayoutCoordinator, TheLayoutSuperAdmin } from "./containers";
import jwtDecode from "jwt-decode";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CHECK_TOKEN } from "./graphql/queries";
import { REFRESH_TOKEN } from "./graphql/mutations";

const coordinator: string = "";

const INTERVAL_CHECK_TOKEN=960; //in seconds
const INTERVAL_REFRESH_TOKEN=900; //in seconds

export const CoordinatorContext = React.createContext(coordinator);

function PrivateRoutes({ ...rest }) {
  const [loggedIn, setLoggedIn] = useState<boolean>(true);
  const [role, setRole] = useState<string | null>(null);
  const [coordinator, setCoordinator] = useState<string>("");

  const [
    checkToken,
    { called, loading, data: data_check_token, error:error_check_token },
  ] = useLazyQuery(CHECK_TOKEN,{fetchPolicy:"network-only"})

  const [refreshToken] = useMutation(REFRESH_TOKEN)


  useEffect(() => {
    checkToken()

    const interval = setInterval(() => {

      checkToken()
    }, INTERVAL_CHECK_TOKEN*1000);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  useEffect(()=>{
    if(data_check_token){
      if (!data_check_token.checkToken){
        setLoggedIn(false);
      }

    }

  },[data_check_token,error_check_token])

  useEffect(()=>{
    const interval = setInterval(() => {

      refreshToken({variables:{token:localStorage.getItem("token")}}).then(res=>{
        console.log(res.data.RefreshToken.token)
        localStorage.setItem("token",res.data.RefreshToken.token)
      }).catch(e=>{
        console.error(e)
        setLoggedIn(false);
      })
    }, INTERVAL_REFRESH_TOKEN*1000);
    return () => clearInterval(interval);
  })

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token) {
      let decoded;
      try {
        decoded = jwtDecode(token);

        setRole(decoded.role);

        if (decoded.role === "coordinator") {
          setCoordinator(decoded.coordinate);
        }
      } catch (e) {
        setLoggedIn(false);
      }
    } else {
      setLoggedIn(false);
    }
  });

  function getLayoutForRole() {
    if (loggedIn) {
      switch (role) {
        case "applicant":
          return <TheLayoutApplicant />;
        case "coordinator":
          return (
            <CoordinatorContext.Provider value={coordinator}>
              {" "}
              <TheLayoutCoordinator />{" "}
            </CoordinatorContext.Provider>
          );
        case "admin":
          return <TheLayoutAdmin />;
        case "superAdmin":
          return <TheLayoutSuperAdmin />;
        default:
          return null;
      }
    } else return <Redirect to="/login" />;
  }

  return <Route {...rest} render={() => getLayoutForRole()} />;
}

export default PrivateRoutes;
