export enum PrefCourses {
  GeoTechnical = "GEO_TECHNICAL",
  SustainableBuilt = "SUSTAINABLE_BUILT",
  Structural = "STRUCTURAL",
  Water = "WATER",
  Highway = "HIGHWAY",
}

export enum PrefDegreeLevel {
  PgDip = "PG_DIP",
  MscEngL9 = "MSC_ENG_L9",
  MscEngL10 = "MSC_ENG_L10",
  MscL9 = "MSC_L9",
  MscL10 = "MSC_L10",
}

export interface StudyPref {
  course: PrefCourses;
  level: PrefDegreeLevel;
}

export enum ApplicationStatus {
  NotComplete = "NOT_COMPLETE",
  Submitted = "SUBMITTED",
  Rejected = "REJECTED",
  SelectedForInterview = "SELECTED_FOR_INTERVIEW", //Shortlisted
  InterviewScheduled = "INTERVIEW_SCHEDULED",
  InterviewCompleted = "INTERVIEW_COMPLETED",
  InterviewRejected = "INTERVIEW_REJECTED",
  WaitingList = "WAITING_LIST",
  Selected = "SELECTED", //Interview Passed
  RejectedFinal = "REJECTED_FINAL",
  SelectedFinal = "SELECTED_FINAL",
}

export enum InterviewStatus {
  Selected = "Selected",
  Rejected = "Rejected",
  Completed = "Completed",
  NotComplete = "NotComplete",
  Waiting = "Waiting",
  Absent = "Absent",
}

export enum FeeType {
  Registration = 'Registration',
  Application = 'Application'
}

export const readableApplicationStatus = (status) => {
  switch (status) {
    case "NOT_COMPLETE":
      return "INCOMPLETE";
    case "SUBMITTED":
      return "APP. SUBMITTED";
    case "REJECTED":
      return "REJECTED";
    case "SELECTED_FOR_INTERVIEW":
      return "SHORTLISTED";
    case "INTERVIEW_SCHEDULED":
      return "INTERVIEW SCHEDULED";
    case "INTERVIEW_COMPLETED":
      return "INTERVIEW COMPLETED";
    case "INTERVIEW_REJECTED":
      return "REJECTED FROM INTERVIEW";
    case "WAITING_LIST":
      return "WAITING LIST";
    case "SELECTED":
      return "SELECTED";
    case "REJECTED_FINAL":
      return "REJECTED AFTER INTERVIEW";
    case "SELECTED_FINAL":
      return "REGISTERED";
    case "SHORTLSITED":
      return "SHORTLISTED";
    case "Payment Proof uploaded":
      return "PAYMENT PROOF UPLOADED";
    default:
      return status;
  }
};

export const readableDegree = (status) => {
  switch (status) {
    case "PG_DIP":
      return "PGDip (SLQF-L8)";
    case "MSC_ENG_L9":
      return "MEng (SLQF-L9)";
    case "MSC_ENG_L10":
      return "MScEng (SLQF-L10)";
    case "MSC_L9":
      return "Masters (SLQF-L9)";
    case "MSC_L10":
      return "MSc (SLQF-L10)";
    default:
      return status;
  }
};

export const readablePrefCourses = (status) => {
  switch (status) {
    case "GEO_TECHNICAL":
      return "Geotechnical Engineering";
    case "SUSTAINABLE_BUILT":
      return "Sustainable Built Environment";
    case "STRUCTURAL":
      return "Structural Engineering";
    case "WATER":
      return "Environmental and Water Engineering";
    case "HIGHWAY":
      return "Highway and Traffic Engineering";
    default:
      return status;
  }
};

export enum ProceedNext {
  Pending = "Pending",
  Yes = "Yes",
  No = "No",
}

export enum ErrorCodes {
  INVALID_LOGIN = "INVALID_LOGIN",
  NOT_FOUND = "NOT_FOUND",
  INPUT_ERROR = "INPUT_ERROR",
  INVALID_STATE = "INVALID_STATE",
  NO_PERMISSION = "NO_PERMISSION",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  INVALID_TOKEN = "INVALID_TOKEN",
  INVALID_VERIFICATION = "INVALID_VERIFICATION",
  EMAIL_ALREADY_EXIST = "EMAIL_ALREADY_EXIST",
}
