import React from "react";

const DashboardApplicant = React.lazy(
  () => import("./views/applicant/dashboard/Dashboard")
);
const NewApplicationApplicant = React.lazy(
  () => import("./views/applicant/new-application/NewApplication")
);
const ProfileApplicant = React.lazy(() => import("./views/profile/Profile"));
const PaymentApplicant = React.lazy(
  () => import("./views/applicant/new-application/documents/payments/Payments")
);

const routesApplicant = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: DashboardApplicant },
  { path: "/profile", name: "Profile", component: ProfileApplicant },
  {
    path: "/new-application",
    name: "New Application",
    component: NewApplicationApplicant,
  },
  { path: "/payment", name: "Payment", component: PaymentApplicant },
];

export default routesApplicant;
