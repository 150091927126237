import axios from "axios";

export const API_URL = process.env.REACT_APP_API_HOST;

export const GRAPHQL_ENDPOINT = API_URL + "/api/graphql";

axios.defaults.baseURL = API_URL;

export const API_GET_REPORTS_APPLICANTS = `api/reports/applicants`;
export const API_GET_REPORTS_INTERVIEWS = `api/reports/interviews`;
export const API_GET_DOWNLOAD_APPLICATION = `api/application/download`;
export const API_GET_DOWNLOAD_APPLICATION_ZIP = `api/application/downloadPackage`;

export const setAxiosAuth = (token) => {
  axios.defaults.headers.common["Authorization"] = token
    ? `Bearer ${token}`
    : "";
};
