import React from "react";
import ViewApplication from "./views/coordinator/view-application/ViewApplication";

const DashboardAdmin = React.lazy(
  () => import("./views/admin/dashboard/Dashboard")
);
const Reports = React.lazy(() => import("./views/admin/reports/Reports"));
// const CoordinatorsAdmin = React.lazy(() =>
//   import("./views/admin/coordinators/Coordinators")
// );

// const ArchiveAdmin = React.lazy(() => import("./views/admin/archive/Archive"));
// const DeadlineAdmin = React.lazy(() => import("./views/admin/deadline/Deadline"));

const InterviewSchedulerAdmin = React.lazy(
  () => import("./views/admin/interviewScheduler/InterviewScheduler")
);

const SelectedApplicants = React.lazy(
  () => import("./views/admin/selected/Selected")
);

const Profile = React.lazy(() => import("./views/profile/Profile"));
const InaugurationAdmin = React.lazy(
  () => import("./views/admin/registration/Registration")
);

const routesAdmin = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: DashboardAdmin },
  { path: "/reports", name: "Reports", component: Reports },
  { path: "/profile", name: "Profile", component: Profile },
  // { path: "/coordinators", name: "Coordinators", component: CoordinatorsAdmin },
  // { path: "/deadlines", name: "Deadline", component: DeadlineAdmin },
  // { path: "/archive", name: "Archive", component: ArchiveAdmin },
  {
    path: "/interview-scheduler",
    name: "Interview Scheduler",
    component: InterviewSchedulerAdmin,
  },

  { path: "/selected", name: "Selected", component: SelectedApplicants },
  { path: "/inauguration", name: "Inauguration", component: InaugurationAdmin },
  {
    path: "/viewApplication",
    name: "View Application",
    component: ViewApplication,
  },
];

export default routesAdmin;
