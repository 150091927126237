import { toast } from "react-toastify";

export function downloadFile(data) {
  const downloadUrl = window.URL.createObjectURL(new Blob([data.data]));
  const link = document.createElement("a");
  link.href = downloadUrl;

  let filename = "applicants.csv";
  let contentDisposition = data.headers["content-disposition"];
  if (contentDisposition) {
    let startFileNameIndex = contentDisposition.indexOf('"') + 1;
    let endFileNameIndex = contentDisposition.lastIndexOf('"');
    filename = contentDisposition.substring(
      startFileNameIndex,
      endFileNameIndex
    );
  }

  link.setAttribute("download", filename); //any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const notifyErrorToast = (message) =>
  toast.error(message, {
    autoClose: 2500,
  });

export const notifySuccessToast = (message) =>
  toast.success(message, {
    autoClose: 2000,
  });
