import React from "react";
import { CFooter } from "@coreui/react";

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div className="mfs-auto">
        <span className="mr-1">Designed and Developed by</span>
        <a href="https://revokex.com" target="_blank">
          RevokeX Technology
        </a>
      </div>
    </CFooter>
  );
};

export default React.memo(TheFooter);
