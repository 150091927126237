import { gql } from "@apollo/client/core";

export const FRAGMENT_APPLICATION_TABLE = gql`
  fragment ApplicationTableFragment on Application {
    id
    nameinit
    homemb
    email
    profilePicUrl
    pref1 {
      course
    }
    mailStatus {
      firstRound
      interviewScheduled
      selectionRejection
    }
    applicationStatus
    currentPreference

    paymentProof {
      bankName
      amount
      paymentSlipPath
      date
    }

    regNo
  }
`;

export const FRAGMENT_INTERVIEW_TABLE = gql`
  ${FRAGMENT_APPLICATION_TABLE}
  fragment interviewTableData on Interview {
    id
    panel
    prefPriority
    course
    notes
    time
    date
    marks
    proceedNext
    application {
      ...ApplicationTableFragment
    }
  }
`;

export const FRAGMENT_INTERVIEW_APPLICANT_DASHBOARD = gql`
  fragment interviewsApplicant on InterviewApplicant {
    id
    panel
    prefPriority
    course
    notes
    time
    date
    proceedNext
  }
`;

export const ALL_APPLICATION_FIELDS = gql`
  fragment allApplicationFields on Application {
    id
    title
    year
    nameinit
    namelong
    nic
    email
    homeaddress
    officeaddress
    hometp
    officetp
    homemb
    officemb
    address_corres
    dob
    nationality
    nameindegree
    profilePicUrl

    #part 2
    employmentPresentDetails {
      address
      designation
      name
    }
    employmentDetails {
      address
      from
      name
      nature
      position
      to
    }
    educationDetails {
      class
      degree
      field
      from
      to
      university
    }
    publicationDetails {
      name
    }

    #part 3
    pref1 {
      course
      level
    }
    pref2 {
      course
      level
    }
    pref3 {
      course
      level
    }
    pref4 {
      course
      level
    }
    pref5 {
      course
      level
    }

    #part 4
    nicPath
    birthCertificatePath
    paymentPath
    degreeCertificatePaths
    transcriptPaths

    #part 5

    referee1 {
      name
      email
      address
    }
    referee2 {
      name
      email
      address
    }
    acceptDeclaration

    #other

    editable
    applicationStatus
    mailStatus {
      firstRound
      interviewScheduled
      selectionRejection
    }

    paymentProof {
      bankName
      amount
      paymentSlipPath
      date
    }

    timeline {
      logItems {
        message
        course
        currentPref
        date
      }
    }

    currentPreference
    regNo

    refereeReport1Submitted
    refereeReport2Submitted
  }
`;

export const ALL_APPLICATION_FIELDS_PARTIAL = gql`
  fragment allApplicationFieldsPartial on ApplicationPartial {
    id
    title
    year
    nameinit
    namelong
    nic
    email
    homeaddress
    officeaddress
    hometp
    officetp
    homemb
    officemb
    address_corres
    dob
    nationality
    nameindegree
    profilePicUrl

    #part 2
    employmentPresentDetails {
      address
      designation
      name
    }
    employmentDetails {
      address
      from
      name
      nature
      position
      to
    }
    educationDetails {
      class
      degree
      field
      from
      to
      university
    }
    publicationDetails {
      name
    }

    #part 3
    pref1 {
      course
      level
    }
    pref2 {
      course
      level
    }
    pref3 {
      course
      level
    }
    pref4 {
      course
      level
    }
    pref5 {
      course
      level
    }

    #part 4
    nicPath
    birthCertificatePath
    paymentPath
    degreeCertificatePaths
    transcriptPaths

    #part 5

    referee1 {
      name
      email
      address
    }
    referee2 {
      name
      email
      address
    }
    acceptDeclaration

    #other

    editable
    applicationStatus
    mailStatus {
      firstRound
      interviewScheduled
      selectionRejection
    }
    paymentProof {
      bankName
      amount
      paymentSlipPath
      date
    }

    timeline {
      logItems {
        message
        course
        currentPref
        date
      }
    }

    # interview:[Interview]

    currentPreference
    regNo
  }
`;
