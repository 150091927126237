import { useLazyQuery } from "@apollo/client";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET_APPLICATION_BY_ID } from "../../../graphql/queries";
import Documents from "./documents/Documents";
import EmploymentEducationalDetails from "./employment-educational-details/EmploymentEducationalDetails";
import PersonalDetails from "./personal-details/PersonalDetails";
import ProgramOfStudy from "./program-of-study/ProgramOfStudy";
import References from "./references-declaration/ReferencesDeclaration";

const ViewApplication = () => {
  const [step, setStep] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true);
  const [applicationData, setApplicationData] = useState(null);

  let location = useLocation();

  const [
    getApplication,
    { called, loading, data: data_application, error },
  ] = useLazyQuery(GET_APPLICATION_BY_ID);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    let appId = query.get("id");

    if (appId) {
      getApplication({ variables: { id: appId } });
    }
  }, []);

  useEffect(() => {
    if (data_application && data_application.application) {
      let a = data_application.application;
      setIsDisabled(true);
      setApplicationData(a);
    }
  }, [data_application]);

  const changeStep = (step) => {
    //console.log("Step changed!" + step);
    setStep(step);
  };
  return (
    <>
      <CCard className="d-none custom-card d-sm-block">
        <CCardBody>
          <CRow className="d-flex justify-content-between">
            <CCol
              className="col-md-auto mr-3 ml-2"
              style={{ cursor: "pointer" }}
              onClick={() => changeStep(1)}
            >
              <CRow className="d-flex align-items-center">
                <div
                  className={`h6 font-weight-bold mr-2 d-flex align-items-center ${
                    step === 1 ? "circle-small-s " : "circle-small-ns "
                  }`}
                >
                  1
                </div>
                <h4
                  id="traffic"
                  className={`h6 ${step !== 1 ? "text-ns" : ""}`}
                >
                  Personal Details
                </h4>
              </CRow>
            </CCol>
            <CCol
              className="col-md-auto mr-3"
              style={{ cursor: "pointer" }}
              onClick={() => changeStep(2)}
            >
              <CRow className="d-flex align-items-center ml-n3">
                <div
                  className={`h6 font-weight-bold mr-2 d-flex align-items-center ${
                    step === 2 ? "circle-small-s " : "circle-small-ns "
                  }`}
                >
                  2
                </div>
                <h4
                  id="traffic"
                  className={`h6 ${step !== 2 ? "text-ns" : ""}`}
                >
                  {" "}
                  Employment & Educational Details
                </h4>
              </CRow>
            </CCol>
            <CCol
              className="col-md-auto mr-3"
              style={{ cursor: "pointer" }}
              onClick={() => changeStep(3)}
            >
              <CRow className="d-flex align-items-center">
                <div
                  className={`h6 font-weight-bold mr-2 d-flex align-items-center ${
                    step === 3 ? "circle-small-s " : "circle-small-ns "
                  }`}
                >
                  3
                </div>
                <h4
                  id="traffic"
                  className={`h6 ${step !== 3 ? "text-ns" : ""}`}
                >
                  Programme of study
                </h4>
              </CRow>
            </CCol>
            <CCol
              className="col-md-auto mr-3"
              style={{ cursor: "pointer" }}
              onClick={() => changeStep(4)}
            >
              <CRow className="d-flex align-items-center">
                <div
                  className={`h6 font-weight-bold mr-2 d-flex align-items-center ${
                    step === 4 ? "circle-small-s " : "circle-small-ns "
                  }`}
                >
                  4
                </div>
                <h4
                  id="traffic"
                  className={`h6 ${step !== 4 ? "text-ns" : ""}`}
                >
                  Documents
                </h4>
              </CRow>
            </CCol>
            <CCol
              className="col-md-auto mr-2"
              style={{ cursor: "pointer" }}
              onClick={() => changeStep(5)}
            >
              <CRow className="d-flex align-items-center">
                <div
                  className={`h6 font-weight-bold mr-2 d-flex align-items-center ${
                    step === 5 ? "circle-small-s " : "circle-small-ns "
                  }`}
                >
                  5
                </div>
                <h4
                  id="traffic"
                  className={`h6 ${step !== 5 ? "text-ns" : ""}`}
                >
                  References & Declaration
                </h4>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      {step === 1 ? (
        <PersonalDetails
          isDisabled={isDisabled}
          onStepChange={(step) => changeStep(step)}
          applicationData={applicationData}
        />
      ) : step === 2 ? (
        <EmploymentEducationalDetails
          isDisabled={isDisabled}
          onStepChange={(step) => changeStep(step)}
          applicationData={applicationData}
        />
      ) : step === 3 ? (
        <ProgramOfStudy
          isDisabled={isDisabled}
          onStepChange={(step) => changeStep(step)}
          applicationData={applicationData}
        />
      ) : step === 4 ? (
        <Documents
          isDisabled={isDisabled}
          onStepChange={(step) => changeStep(step)}
          applicationData={applicationData}
        />
      ) : step === 5 ? (
        <References
          isDisabled={isDisabled}
          onStepChange={(step) => changeStep(step)}
          applicationData={applicationData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ViewApplication;
