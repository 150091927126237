import React from "react";
import { TheContentCoordinator, TheFooter, TheHeaderCoordinator, TheSidebarCoordinator } from "./index";

const TheLayoutCoordinator = () => {
  return (
    <div className="c-app c-default-layout">
      <TheSidebarCoordinator />
      <div className="c-wrapper">
        <TheHeaderCoordinator />
        <div className="c-body">
          <TheContentCoordinator />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default TheLayoutCoordinator;
