import { useMutation, useQuery } from "@apollo/client";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInputFile,
  CLabel,
  CLink,
  CRow
} from "@coreui/react";
import React, { useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SAVE_APPLICATION_PART4 } from "../../../../graphql/mutations";
import { GET_FEES, GET_MY_APPLICATION } from "../../../../graphql/queries";
import { notifyErrorToast } from "../../../utils/misc";

const Documents = ({ onStepChange, isDisabled, applicationData }) => {
  const [saveApplicationPart4, { data, loading, called }] = useMutation(
    SAVE_APPLICATION_PART4,
    {
      refetchQueries: [{ query: GET_MY_APPLICATION }],
    }
  );

  const {data:data_fees}=useQuery(GET_FEES)

  const [documentDetails, setDocumentDetails] = useState({
    bc: "",
    nic: "",
    processingfee: "",
  });

  const [isDocumentsUploaded, setIsDocumentsUploaded] = useState({});

  const [isDegreeUploaded, setIsDegreeUploaded] = useState({});

  const [isTranscriptUploaded, setIsTranscriptUploaded] = useState({});

  const [isErrors, setIsErrors] = useState(false);

  const handleNext = () => {
    let process_flag = true;
    for (let key in isDocumentsUploaded) {
      if (isDocumentsUploaded.hasOwnProperty(key)) {
        if (isDocumentsUploaded[key] === false) {
          setIsErrors(true);
          process_flag = false;
          break;
        }
      }
    }

    for (let key1 in isDegreeUploaded) {

      if (isDegreeUploaded.hasOwnProperty(key1)) {
        if (isDegreeUploaded[key1] === false) {
          setIsErrors(true);
          process_flag = false;
          break;
        }
      }
    }

    for (let key2 in isTranscriptUploaded) {
      if (isTranscriptUploaded.hasOwnProperty(key2)) {
        if (isTranscriptUploaded[key2] === false) {
          setIsErrors(true);
          process_flag = false;
          break;
        }
      }
    }

    if (Object.keys(isDocumentsUploaded).length < 3) {
      setIsErrors(true);
      process_flag = false;
    }

    if (Object.keys(isDegreeUploaded).length < Object.keys(degFiles).length) {
      setIsErrors(true);
      process_flag = false;
    }

    if (
      Object.keys(isTranscriptUploaded).length <
      Object.keys(transcriptFiles).length
    ) {
      setIsErrors(true);
      process_flag = false;
    }

    if (
      applicationData.birthCertificatePath &&
      applicationData.nicPath &&
      applicationData.paymentPath &&
      applicationData.degreeCertificatePaths &&
      applicationData.degreeCertificatePaths.length > 0 &&
      applicationData.transcriptPaths &&
      applicationData.transcriptPaths.length > 0
    ) {
      process_flag = true;
    }

    if (process_flag) {
      let variables = {};

      if (documentDetails.bc[0]) {
        variables.birthCertificate = documentDetails.bc[0];
      }
      if (documentDetails.nic[0]) {
        variables.nic = documentDetails.nic[0];
      }
      if (documentDetails.processingfee[0]) {
        variables.payment = documentDetails.processingfee[0];
      }

      let tp = transcriptFiles.map((item) => item.file);

      if (tp && tp.length > 0 && tp[0] !== "") {
        variables.transcripts = tp;
      }

      let dp = degFiles.map((item) => item.file);

      if (dp && dp.length > 0 && dp[0] !== "") {
        variables.degreeCertificates = dp;
      }


      saveApplicationPart4({
        variables: variables,
      })
        .then((result) => {
          onStepChange(5);
        })
        .catch((err) => {
          notifyErrorToast(err.message);
        });
    }
  };

  const validateFiles = (file) => {
    let flag = true;
    if (file) {
      if (file.size > 5242880) {
        notifyErrorToast("File size too large. Keep it < 5MB !");
        flag = false;
      }
      if (
        file.type !== "application/pdf" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/png"
      ) {
        notifyErrorToast("Allowed files types are PDF, JPG and PNG!");
        flag = false;
        //console.log(file.type);
      }
      return flag;
    }
  };
  const handleDocumentUpload = (e) => {
    if (e) {
      let id = e.target.id;
      let file = e.target.files[0];
      let flag = validateFiles(e.target.files[0]);
      //console.log(e.target.files[0]);
      if (file && flag) {
        setDocumentDetails({ ...documentDetails, [id]: [file] });
        setIsDocumentsUploaded({ ...isDocumentsUploaded, [id]: true });
      }
    }
  };

  const [degFiles, setDegreeFiles] = useState([
    {
      name: "",
      file: "",
    },
  ]);

  const addDegree = () => {
    var temp_deg = {
      name: "",
      file: "",
    };
    setDegreeFiles((degFiles) => [...degFiles, temp_deg]);
  };

  const removeDegree = (index) => {
    setDegreeFiles((degFiles) =>
      // Filter out the item with the matching index
      degFiles.filter((value, i) => i !== index)
    );
  };

  const handleDegreeUpload = (e) => {
    if (e) {
      let id = e.target.id;
      let file = e.target.files[0];
      let [r_name, index] = id.split("_");
      let flag = validateFiles(e.target.files[0]);
      let temp_array = [...degFiles];
      if (file) {
        temp_array[index]["file"] = file;
        temp_array[index]["name"] = file.name;
      }
      if (file && flag) {
        setDegreeFiles(temp_array);
        setIsDegreeUploaded({ ...isDegreeUploaded, [index]: true });
      }
    }
  };

  const [transcriptFiles, setTranscriptFiles] = useState([
    {
      name: "",
      file: "",
    },
  ]);

  const addDTranscript = (file_name) => {
    var temp_trans = {
      name: file_name,
    };
    setTranscriptFiles((transcriptFiles) => [...transcriptFiles, temp_trans]);
  };

  const removeTranscript = (index) => {
    // console.log("PPP")
    setTranscriptFiles((transcriptFiles) =>
      // Filter out the item with the matching index
      transcriptFiles.filter((value, i) => i !== index)
    );
  };

  const handleTranscriptUpload = (e) => {
    if (e) {
      let id = e.target.id;
      let file = e.target.files[0];
      let [r_name, index] = id.split("_");
      let flag = validateFiles(e.target.files[0]);
      let temp_array = [...transcriptFiles];
      if (file) {
        temp_array[index]["file"] = file;
        temp_array[index]["name"] = file.name;
      }
      if (file && flag) {
        setTranscriptFiles(temp_array);
        setIsTranscriptUploaded({ ...isTranscriptUploaded, [index]: true });
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <CCard className="custom-card">
        <CCardHeader className="custom-card-header">Documents</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup row>
                <CLabel
                  col
                  md={4}
                  className="h6"
                  style={{ marginBottom: "0rem" }}
                >
                  Birth Certificate (Copy){" "}
                  <span style={{ color: "#ab0202" }}> *</span>
                </CLabel>
                <CCol
                  xs="12"
                  md="8"
                  style={{ alignSelf: "flex-end", paddingBottom: "10px" }}
                >
                  <CInputFile
                    type="file"
                    custom
                    id="bc"
                    onChange={handleDocumentUpload}
                    disabled={isDisabled}
                  />
                  <CLabel htmlFor="bc" variant="custom-file">
                    {isDocumentsUploaded.bc
                      ? documentDetails.bc[0].name
                      : "Choose File..."}
                  </CLabel>
                </CCol>

                {applicationData && applicationData.birthCertificatePath ? (
                  <>
                    <CCol xs="12" md="4"></CCol>
                    <CCol xs="12" md="8" style={{ marginLeft: "-11px" }}>
                      <CLink
                        href={`${applicationData.birthCertificatePath}`}
                        color="primary"
                        target="_blank"
                      >
                        View Uploaded Birth Certificate
                      </CLink>
                    </CCol>
                  </>
                ) : null}
              </CFormGroup>
            </CCol>

            <CCol xs="12" md="6" xl="6">
              <CFormGroup row className="mr-2 ml-2">
                <CLabel col md={3} className="h6">
                  NIC (Copy) <span style={{ color: "#ab0202" }}> *</span>
                </CLabel>
                <CCol
                  xs="12"
                  md="9"
                  style={{ alignSelf: "flex-end", paddingBottom: "10px" }}
                >
                  <CInputFile
                    type="file"
                    custom
                    id="nic"
                    onChange={handleDocumentUpload}
                    disabled={isDisabled}
                  />
                  <CLabel htmlFor="nic" variant="custom-file">
                    {isDocumentsUploaded.nic
                      ? documentDetails.nic[0].name
                      : "Choose File..."}
                  </CLabel>
                </CCol>

                {applicationData && applicationData.nicPath ? (
                  <>
                    <CCol xs="12" md="3"></CCol>
                    <CCol xs="12" md="9" style={{ marginLeft: "-11px" }}>
                      <CLink
                        href={`${applicationData.nicPath}`}
                        target="_blank"
                        color="primary"
                      >
                        View Uploaded NIC
                      </CLink>
                    </CCol>
                  </>
                ) : null}
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup row>
                <CLabel
                  col
                  md={4}
                  className="h6"
                  style={{ marginBottom: "0rem" }}
                >
                  Proof of Payment - Application Processing Fee (Deposit Slip)
                  <span style={{ color: "#ab0202" }}> *</span>
                </CLabel>
                <CCol
                  xs="12"
                  md="8"
                  style={{ alignSelf: "flex-end", paddingBottom: "10px" }}
                >
                  <CInputFile
                    type="file"
                    multiple={false}
                    custom
                    id="processingfee"
                    onChange={handleDocumentUpload}
                    disabled={isDisabled}
                  />
                  <CLabel htmlFor="processingfee" variant="custom-file">
                    {isDocumentsUploaded.processingfee
                      ? documentDetails.processingfee[0].name
                      : "Choose File..."}
                  </CLabel>
                </CCol>

                {applicationData && applicationData.paymentPath ? (
                  <>
                    <CCol xs="12" md="4"></CCol>
                    <CCol
                      xs="12"
                      md="8"
                      style={{ marginBottom: "1rem", marginLeft: "-11px" }}
                    >
                      <CLink
                        href={`${applicationData.paymentPath}`}
                        color="primary"
                        target="_blank"
                      >
                        View Payment Slip
                      </CLink>
                    </CCol>
                  </>
                ) : null}
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="12" xl="12">
              <CFormGroup>
                <span className="h6">
                  Degree / Diploma Certificate/s (Copy)
                </span>
                <span style={{ color: "#ab0202" }}> *</span>
              </CFormGroup>
            </CCol>
          </CRow>

          {degFiles.map((pub, ids) => {
            return (
              <CRow
                style={{
                  padding: 15,
                  marginLeft: 2,
                  marginRight: 3,
                  border: `2px solid #d8dbe0`,
                  borderRadius: 15,
                }}
                className="mb-5"
                key={ids}
              >
                <CCol xs="12" md="12" xl="12">
                  <CFormGroup>
                    {/* <CLabel htmlFor="presentdesignation" className="h6">Degree / Diploma Certificate/s (Copy)</CLabel> */}

                    <CCol xs="12">
                      <CInputFile
                        type="file"
                        custom
                        id={"degree_" + ids}
                        multiple={false}
                        onChange={handleDegreeUpload}
                        disabled={isDisabled}
                      />
                      <CLabel htmlFor={"degree_" + ids} variant="custom-file">
                        {isDegreeUploaded[ids]
                          ? degFiles[ids].name
                          : "Attach Degree/Diploma Certificate"}
                      </CLabel>
                    </CCol>
                  </CFormGroup>

                  {!isDisabled ? (
                    <CButton
                      type="submit"
                      size="md"
                      color="primary"
                      onClick={() => addDegree()}
                    >
                      Add More&nbsp;
                      <CIcon name="cil-plus" />
                    </CButton>
                  ) : (
                    <></>
                  )}
                  {ids !== 0 && !isDisabled ? (
                    <CButton
                      type="submit"
                      size="md"
                      color="danger"
                      className="ml-2"
                      onClick={() => removeDegree(ids)}
                    >
                      Remove&nbsp;&nbsp;
                      <CIcon name="cil-minus" />{" "}
                    </CButton>
                  ) : (
                    <></>
                  )}
                </CCol>
              </CRow>
            );
          })}

          <CRow
            style={{
              marginTop: "-2.7rem",
              marginBottom: "2.7rem",
              paddingLeft: "2rem",
            }}
          >
            {applicationData && applicationData.degreeCertificatePaths
              ? applicationData.degreeCertificatePaths.map((item, index) => (
                <CCol xs="12" md="8">
                  <CLink href={`${item}`} color="primary" target="_blank">
                    View certificate {index + 1}
                  </CLink>
                </CCol>
              ))
              : null}
          </CRow>

          <CRow>
            <CCol xs="12" md="12" xl="12">
              <CFormGroup>
                <span className="h6">Academic Transcript/s (Copy)</span>
                <span style={{ color: "#ab0202" }}> *</span>
              </CFormGroup>
            </CCol>
          </CRow>

          {transcriptFiles.map((pub, idx) => {
            return (
              <CRow
                style={{
                  padding: 15,
                  marginLeft: 2,
                  marginRight: 3,
                  border: `2px solid #d8dbe0`,
                  borderRadius: 15,
                }}
                className="mb-5"
                key={idx + "t"}
              >
                <CCol xs="12" md="12" xl="12">
                  <CFormGroup>
                    {/* <CLabel htmlFor="presentdesignation" className="h6">Degree / Diploma Certificate/s (Copy)</CLabel> */}

                    <CCol xs="12">
                      <CInputFile
                        type="file"
                        custom
                        id={"transcript_" + idx}
                        multiple={false}
                        onChange={handleTranscriptUpload}
                        disabled={isDisabled}
                      />
                      <CLabel
                        htmlFor={"transcript_" + idx}
                        variant="custom-file"
                      >
                        {isTranscriptUploaded[idx]
                          ? transcriptFiles[idx].name
                          : "Attach Academic Transcript/s (Copy)"}
                      </CLabel>
                    </CCol>
                  </CFormGroup>

                  {!isDisabled ? (
                    <CButton
                      type="submit"
                      size="md"
                      color="primary"
                      onClick={() => addDTranscript()}
                    >
                      Add More&nbsp;
                      <CIcon name="cil-plus" />
                    </CButton>
                  ) : (
                    <></>
                  )}
                  {idx !== 0 && !isDisabled ? (
                    <CButton
                      type="submit"
                      size="md"
                      color="danger"
                      className="ml-2"
                      onClick={() => removeTranscript(idx)}
                    >
                      Remove&nbsp;&nbsp;
                      <CIcon name="cil-minus" />{" "}
                    </CButton>
                  ) : (
                    <></>
                  )}
                </CCol>
              </CRow>
            );
          })}

          <CRow
            style={{
              marginTop: "-2.7rem",
              marginBottom: "2.7rem",
              paddingLeft: "2rem",
            }}
          >
            {applicationData && applicationData.transcriptPaths
              ? applicationData.transcriptPaths.map((item, index) => (
                <CCol xs="12" md="8">
                  <CLink href={`${item}`} color="primary" target="_blank">
                    View transcript {index + 1}
                  </CLink>
                </CCol>
              ))
              : null}
          </CRow>

          <CRow>
            <CCol xs="12" md="12" xl="12">
              <CFormGroup
                style={{
                  border: `2px solid #d8dbe0`,
                  borderRadius: 15,
                  padding: "15px",
                }}
                className="d-flex-row"
              >
                <span className="h6">NOTE 1:</span>
                <span>
                  {" "}
                  Originals should be produced on request/at the time of
                  interview.
                </span>
                <br />
                <br />
                <span className="h6">NOTE 2:</span>
                <span>
                  {" "}
                  A payment of Rs.{data_fees && data_fees.applicationFee?data_fees.applicationFee.amount:"0.00"} has to be done to the below account as
                  the non-refundable application processing fee.
                </span>
                <div className="mt-3 h6" style={{ lineHeight: 1.7 }}>
                  Bank : Bank of Ceylon
                  <br />
                  Branch : Peradeniya <br /> Name of Account : Research and Fund
                  Account <br /> Account Number : 001274688
                </div>
              </CFormGroup>
            </CCol>
          </CRow>
          <div
            className={isErrors ? "small mb-2" : "d-sm-none mb-2"}
            style={{ color: "#ab0202" }}
          >
            * Please upload all compulsory documents!
          </div>
          <hr />
          <CRow>
            <CCol xs="12" md="12" xl="12" className="d-flex flex-row-reverse">
              {isDisabled ? (
                <CButton
                  className="ml-4"
                  type="submit"
                  size="md"
                  color="primary"
                  onClick={() => onStepChange(5)}
                  disabled={loading}
                >
                  Next&nbsp;&nbsp;
                  <CIcon name="cil-arrow-right" />{" "}
                </CButton>
              ) : (
                <CButton
                  className="ml-4"
                  type="submit"
                  size="md"
                  color="primary"
                  disabled={loading}
                  onClick={() => handleNext()}
                >
                  Next&nbsp;&nbsp;
                  <CIcon name="cil-arrow-right" />{" "}
                </CButton>
              )}

              <CButton
                type="submit"
                size="md"
                color="primary"
                disabled={loading}
                onClick={() => onStepChange(3)}
              >
                <CIcon name="cil-arrow-left" /> &nbsp;&nbsp;Back
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
        {loading ? (
          <div className="loadingWrapper">
            <div className="loadingCSS">
              <BounceLoader
                color={"#012C7F"}
                loading={loading}
                size={105}
                speedMultiplier={0.8}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </CCard>
    </>
  );
};

export default Documents;
