import { useMutation, useQuery } from "@apollo/client";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputFile,
  CInputRadio,
  CInvalidFeedback,
  CLabel,
  CRow,
  CSelect,
  CTextarea
} from "@coreui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_GET_DOWNLOAD_APPLICATION_ZIP } from "../../../../api";
import { SAVE_APPLICATION_PART1 } from "../../../../graphql/mutations";
import { GET_APPLICATION_YEAR, GET_MY_APPLICATION } from "../../../../graphql/queries";
import { API_URL } from "../../../../index";
import { notifyErrorToast } from "../../../utils/misc";
// import {SINGLE_UPLOAD} from "../../../../graphql/mutations";

const PersonalDetails = ({ isDisabled, onStepChange, applicationData }) => {
  const {
    data: data_year,
    loading: loading_year,
    error: error_year,
  } = useQuery(GET_APPLICATION_YEAR);

  const [saveApplicationPart1, { data, loading, called }] = useMutation(
    SAVE_APPLICATION_PART1,
    {
      refetchQueries: [{ query: GET_MY_APPLICATION }],
    }
  );

  const [personalDetails, setPersonalDetails] = useState({
    title: "Mr.",
    year: null,
    nameinit: "",
    namelong: "",
    nic: "",
    email: "",
    homeaddress: "",
    officeaddress: "",
    hometp: "",
    officetp: "",
    homemb: "",
    officemb: "",
    address_corres: "Home",
    dob: "",
    nationality: "",
    nameindegree: "",
  });
  // const [isDisabled,setIsDisabled] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [profPicDisplay, setIsProfPicDisplay] = useState("avatar-sample.jpg");

  useEffect(() => {
    if (data_year) {
      setValue("year", data_year.currentApplicationYear);
    }
  }, [data_year]);


  useEffect(() => {
    if (applicationData) {
      setValue("title", applicationData.title);
      setValue("nameinit", applicationData.nameinit);
      setValue("namelong", applicationData.namelong);
      setValue("nic", applicationData.nic);
      setValue("email", applicationData.email);
      setValue("homeaddress", applicationData.homeaddress);
      setValue("officeaddress", applicationData.officeaddress);
      setValue("hometp", applicationData.hometp);
      setValue("officetp", applicationData.officetp);
      setValue("homemb", applicationData.homemb);
      setValue("officemb", applicationData.officemb);
      setValue("address_corres", applicationData.address_corres);
      setValue("dob", moment(applicationData.dob).format("YYYY-MM-DD"));
      setValue("nationality", applicationData.nationality);
      setValue("nameindegree", applicationData.nameindegree);

      setIsProfPicDisplay(applicationData.profilePicUrl);
      setIsUploaded(true);
    }
  }, [applicationData]);

  const handleNextDisabled = () => {
    onStepChange(2);
  };

  const handleSend = (formData) => {
    saveApplicationPart1({ variables: { input: { ...formData } } })
      .then((data) => {
        onStepChange(2);
      })
      .catch((e) => {
        notifyErrorToast("Application save failed");
      });
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const uploadedImage = React.useRef(null);

  const handleImageUpload = (e) => {
    //handleOnChange(e);
    const [file] = e.target.files;
    setIsProfPicDisplay(URL.createObjectURL(e.target.files[0]));
    //console.log(URL.createObjectURL( e.target.files[0]));
    if (file) {
      // const reader = new FileReader();
      // const { current } = uploadedImage;
      // current.file = file;
      // reader.onload = (e) => {
      //   current.src = e.target.result;
      // };
      // reader.readAsDataURL(file);
      setIsUploaded(true);
      setValue("profilepic", file);

      setPersonalDetails({
        ...personalDetails,
        profilepic: file,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <CCard className="custom-card">
        <CCardHeader className="custom-card-header">
          Personal Details
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <div
                  style={{
                    height: "136px",
                    width: "105px",
                    border: "2px solid",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={profPicDisplay}
                  />
                </div>
              </CFormGroup>
              <CFormGroup>
                <CLabel htmlFor="year" className="h6">
                  Profile Image
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <div
                  className={
                    !isUploaded ? "small text-muted mb-2" : "d-sm-none mb-2"
                  }
                >
                  Please upload a recently captured 35mm x 45mm (Passport size)
                  photo.
                </div>
                <CInputFile
                  type="file"
                  id="profilepic"
                  name="profilepic"
                  onChange={handleImageUpload}
                  accept="image/*"
                  multiple={false}
                  invalid={errors.profilepic}
                  disabled={isDisabled}
                />
                <CInvalidFeedback>
                  Please upload a photo to continue!
                </CInvalidFeedback>
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6" xl="6">
              <CLabel htmlFor="select" className="h6">
                Title
              </CLabel>
              <Controller
                render={({ field: { value, ref, onChange } }) => (
                  <CSelect
                    custom
                    id="title"
                    name="title"
                    onChange={onChange}
                    disabled={isDisabled}
                    value={value}
                    defaultValue="Mr."
                  >
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Ms.">Ms.</option>
                  </CSelect>
                )}
                {...register("title", {
                  required: true,
                })}
                control={control}
              />
            </CCol>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="year" className="h6">
                  Year of Application
                </CLabel>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      id="year"
                      value={value}
                      onChange={onChange}
                      disabled
                    />
                  )}
                  {...register("year", {
                    required: true,
                  })}
                  control={control}
                />
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6" xl="6" rowSpan="2">
              <CFormGroup>
                <CLabel htmlFor="nameinit" className="h6">
                  Name with Initials (Seperate initials with space)
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      id="nameinit"
                      name="nameinit"
                      placeholder="Enter name with Initials"
                      onChange={onChange}
                      disabled={isDisabled}
                      invalid={errors.nameinit}
                      value={value}
                    />
                  )}
                  {...register("nameinit", {
                    pattern: /(([A-Z]\s)+([A-Z][a-z]+\s?)+)/,
                    required: true,
                  })}
                  control={control}
                />
                <CInvalidFeedback>
                  Please use format : T N Jayawardane
                </CInvalidFeedback>
              </CFormGroup>
            </CCol>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="namelong" className="h6">
                  Names denoted by Initials
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      id="namelong"
                      name="namelong"
                      placeholder="Enter names denoted by Initials"
                      required
                      invalid={errors.namelong}
                      onChange={onChange}
                      disabled={isDisabled}
                      value={value}
                    />
                  )}
                  {...register("namelong", {
                    required: true,
                  })}
                  control={control}
                />
                <CInvalidFeedback>This field cannot be empty!</CInvalidFeedback>
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="nic" className="h6">
                  NIC/Passport No.
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      id="nic"
                      name="nic"
                      placeholder="Enter NIC/Passport Number"
                      required
                      invalid={errors.nic}
                      onChange={onChange}
                      disabled={isDisabled}
                      value={value}
                    />
                  )}
                  {...register("nic", {
                    required: true,
                  })}
                  control={control}
                />
                <CInvalidFeedback>
                  Please enter valid NIC/Passport number!
                </CInvalidFeedback>
              </CFormGroup>
            </CCol>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="email" className="h6">
                  Email
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      required
                      invalid={errors.email}
                      onChange={onChange}
                      disabled={isDisabled}
                      value={value}
                    />
                  )}
                  {...register("email", {
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                    required: true,
                  })}
                  control={control}
                />
                <CInvalidFeedback>Please enter a valid email!</CInvalidFeedback>
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="homeaddress" className="h6">
                  Home Address
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CTextarea
                      name="homeaddress"
                      id="homeaddress"
                      rows="4"
                      placeholder="Enter your Home Address"
                      required
                      invalid={errors.homeaddress}
                      onChange={onChange}
                      disabled={isDisabled}
                      value={value}
                    />
                  )}
                  {...register("homeaddress", {
                    required: true,
                  })}
                  control={control}
                />
                <CInvalidFeedback>This field cannot be empty!</CInvalidFeedback>
              </CFormGroup>
            </CCol>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="officeaddress" className="h6">
                  Official Address
                </CLabel>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CTextarea
                      name="officeaddress"
                      id="officeaddress"
                      rows="4"
                      
                      required
                      onChange={onChange}
                      disabled={isDisabled}
                      value={value}
                    />
                  )}
                  {...register("officeaddress")}
                  control={control}
                />
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="hometp" className="h6">
                  Home Telephone Number
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      id="hometp"
                      name="hometp"
                      placeholder="Enter Home Telephone Number"
                      required
                      onChange={onChange}
                      disabled={isDisabled}
                      value={value}
                      invalid={errors.hometp}
                    />
                  )}
                  {...register("hometp", {
                    pattern: /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|5|6|7|8)\d)\d{6}$/,
                    required: true,
                  })}
                  control={control}
                />
                <CInvalidFeedback>
                  Please enter a valid mobile number!
                </CInvalidFeedback>
              </CFormGroup>
            </CCol>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="officetp" className="h6">
                  Official Telephone Number
                </CLabel>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      id="officetp"
                      name="officetp"
                      
                      onChange={onChange}
                      disabled={isDisabled}
                      value={value}
                    />
                  )}
                  {...register("officetp")}
                  control={control}
                />
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="homemb" className="h6">
                  Personal Mobile Number
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      id="homemb"
                      name="homemb"
                      placeholder="Enter Personal Mobile Number"
                      invalid={errors.homemb}
                      onChange={onChange}
                      disabled={isDisabled}
                      required
                      value={value}
                    />
                  )}
                  {...register("homemb", {
                    pattern: /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)([0234579])|7([01245678])\d)\d{6}$/,
                    required: true,
                  })}
                  control={control}
                />
                <CInvalidFeedback>
                  Please enter a valid mobile number!
                </CInvalidFeedback>
              </CFormGroup>
            </CCol>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="officemb" className="h6">
                  Official Mobile Number
                </CLabel>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      id="officemb"
                      name="officemb"
                      placeholder="Enter Official Mobile Number"
                      onChange={onChange}
                      disabled={isDisabled}
                      value={value}
                    />
                  )}
                  {...register("officemb")}
                  control={control}
                />
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow className="mt-2 mb-2">
            <CCol xs="8" md="8" xl="8">
              <CFormGroup>
                <span className="h6">
                  Address for correspondence (Assistant Registrar should be
                  informed any changes immediately):
                </span>
                <span style={{ color: "#ab0202" }}> *</span>
              </CFormGroup>
            </CCol>
            <CCol xs="4" md="4" xl="4" className="d-flex">
              <CFormGroup variant="checkbox" className="mr-4">
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInputRadio
                      className="form-check-input"
                      defaultChecked
                      id="radio1"
                      name="address_corres"
                      onChange={onChange}
                      value="Home"
                      disabled={isDisabled}
                      checked={getValues("address_corres") === "Home"}
                    />
                  )}
                  {...register("address_corres")}
                  control={control}
                />
                <CLabel variant="checkbox" htmlFor="radio1">
                  Home
                </CLabel>
              </CFormGroup>
              <CFormGroup variant="checkbox">
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInputRadio
                      className="form-check-input"
                      id="radio2"
                      name="address_corres"
                      onChange={onChange}
                      value="Official"
                      disabled={isDisabled}
                      checked={getValues("address_corres") === "Official"}
                    />
                  )}
                  {...register("address_corres")}
                  control={control}
                />
                <CLabel variant="checkbox" htmlFor="radio2">
                  Official
                </CLabel>
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="dob" className="h6">
                  Date of Birth
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      type="date"
                      id="dob"
                      name="dob"
                      invalid={errors.dob}
                      onChange={onChange}
                      placeholder="date"
                      disabled={isDisabled}
                      value={value}
                    />
                  )}
                  {...register("dob", {
                    required: true,
                  })}
                  control={control}
                />
                <CInvalidFeedback>This field cannot be empty!</CInvalidFeedback>
              </CFormGroup>
            </CCol>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="nationality" className="h6">
                  Nationality
                </CLabel>
                <span style={{ color: "#ab0202" }}> *</span>
                <Controller
                  render={({ field: { value, ref, onChange } }) => (
                    <CInput
                      name="nationality"
                      id="nationality"
                      placeholder="Enter Nationality"
                      onChange={onChange}
                      invalid={errors.nationality}
                      disabled={isDisabled}
                      value={value}
                    />
                  )}
                  {...register("nationality", {
                    required: true,
                  })}
                  control={control}
                />
                <CInvalidFeedback>This field cannot be empty!</CInvalidFeedback>
              </CFormGroup>
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol xs="12" md="12" xl="12">
              <CFormGroup
                style={{
                  border: `2px solid #d8dbe0`,
                  borderRadius: 15,
                  padding: "15px",
                }}
              >
                <h6 className="mt-3 mb-3">
                  TO ALL APPLICANTS OF THE POSTGRADUATE PROGRAMMES IN CIVIL
                  ENGINEERING CONFIRMATION OF NAME TO BE APPEARED IN THE DEGREE
                  CERTIFICATE
                </h6>
                <p>
                  Please note that you are required to indicate in the following
                  cage the manner in which your name should be spelt in the
                  degree certificate to be awarded to you at the time of
                  graduation.
                </p>

                <CFormGroup className="mt-5 mb-5">
                  <CLabel htmlFor="nameindegree" className="h6">
                    Name to be appeared in Degree Certificate
                  </CLabel>
                  <span style={{ color: "#ab0202" }}> *</span>
                  <Controller
                    render={({ field: { value, ref, onChange } }) => (
                      <CInput
                        id="nameindegree"
                        placeholder="Enter Name to be appeared in Degree Certificate"
                        name="nameindegree"
                        onChange={onChange}
                        invalid={errors.nameindegree}
                        disabled={isDisabled}
                        value={value}
                      />
                    )}
                    {...register("nameindegree", {
                      required: true,
                    })}
                    control={control}
                  />
                  <CInvalidFeedback>
                    This field cannot be empty!
                  </CInvalidFeedback>
                </CFormGroup>
                <p className="mt-3 mb-3">
                  Please indicate your full name in BLOCK LETTERS as appeared
                  either in your first degree certificate that has been awarded
                  to you at the convocation or a detailed certificate which has
                  been forwarded to the Postgraduate programme of department of
                  Civil Engineering at the time of your first registration, a
                  copy of which (transcript or a degree certificate) should also
                  be attached for our reference when you apply for the final
                  examination.
                </p>
                <p className="mt-3 mb-3">
                  Please note that your name will be indicated in the degree
                  certificate as appearing above and that no changes will be
                  made to it once you have given this form to the Postgraduate
                  programme of department of Civil Engineering, except in case
                  where a female candidate could make a request to change her
                  name after the marriage. In such a case, those candidates are
                  required to submit a certified copy of the marriage
                  certificate for our reference.
                </p>
              </CFormGroup>
            </CCol>
          </CRow>
          <div
            className={
              Object.keys(errors).length > 0 ? "small mb-2" : "d-sm-none mb-2"
            }
            style={{ color: "#ab0202" }}
          >
            * Please fill out all compulsory fields!
          </div>
          <hr />
          <CRow>
            <CCol xs="12" md="12" xl="12" className="d-flex flex-row-reverse">
              {isDisabled ? (
                <CButton
                  className="ml-4"
                  type="submit"
                  size="md"
                  color="primary"
                  onClick={() => handleNextDisabled()}
                  disabled={loading}
                >
                  Next&nbsp;&nbsp;
                  <CIcon name="cil-arrow-right" />{" "}
                </CButton>
              ) : (
                <CButton
                  className="ml-4"
                  type="submit"
                  size="md"
                  color="primary"
                  onClick={handleSubmit(handleSend)}
                  disabled={loading}
                >
                  Next&nbsp;&nbsp;
                  <CIcon name="cil-arrow-right" />{" "}
                </CButton>
              )}
              {applicationData ? (
                <CButton
                  size="md"
                  color="info"
                  style={{ minWidth: 180, marginRight: 15 }}
                  target="_blank"
                  href={`${API_URL}/${API_GET_DOWNLOAD_APPLICATION_ZIP}/${applicationData.id}`}
                >
                  Download Application Package&nbsp;&nbsp;
                  <CIcon name="cil-cloud-download" />
                </CButton>
              ) : null}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

export default PersonalDetails;
