import React from "react";

// const DashboardCoordinator = React.lazy(() =>
//   import("./views/coordinator/dashboard/Dashboard")
// );
const ApplicationsCoordinator = React.lazy(
  () => import("./views/coordinator/applications/Applications")
);
const InterviewedCoordinator = React.lazy(
  () => import("./views/coordinator/interviewed/Interviewed")
);
const InterviewResultsCoordinator = React.lazy(
  () => import("./views/coordinator/interviewResults/InterviewResults")
);
// const InterviewSchedulerCoordinator = React.lazy(() =>
//   import("./views/coordinator/interviewScheduler/InterviewScheduler")
// );
const SelectedCoordinator = React.lazy(
  () => import("./views/coordinator/selected/Selected")
);
const RegistrationCoordinator = React.lazy(
  () => import("./views/coordinator/registration/Registration")
);
const Profile = React.lazy(() => import("./views/profile/Profile"));
const ViewApplication = React.lazy(
  () => import("./views/coordinator/view-application/ViewApplication")
);
const routesCoordinator = [
  { path: "/", exact: true, name: "Home" },
  // { path: "/dashboard", name: "Dashboard", component: DashboardCoordinator },
  { path: "/profile", name: "Profile", component: Profile },
  {
    path: "/applications",
    name: "Applications",
    component: ApplicationsCoordinator,
  },
  {
    path: "/interviewed",
    name: "Interviewed",
    component: InterviewedCoordinator,
  },
  // {
  //   path: "/interview-scheduler",
  //   name: "Interview Scheduler",
  //   component: InterviewSchedulerCoordinator,
  // },
  {
    path: "/interview-results",
    name: "Interview Results",
    component: InterviewResultsCoordinator,
  },
  { path: "/selected", name: "Selected", component: SelectedCoordinator },
  {
    path: "/registration",
    name: "Registration",
    component: RegistrationCoordinator,
  },
  {
    path: "/viewApplication",
    name: "View Application",
    component: ViewApplication,
  },
];

export default routesCoordinator;
