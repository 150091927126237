import { gql } from "@apollo/client";

export const SIGNUP_APPLICANT = gql`
  mutation signupApplicant($input: ApplicantSignupInput!) {
    ApplicantSignup(input: $input) {
      token
    }
  }
`;

export const SEND_EMAIL_VERIFICATION_CODE = gql`
  mutation sendEmailVericationCode($email: String!) {
    SendEmailVerificationCode(email: $email)
  }
`;

export const SEND_PASSWORD_RESET_LINK = gql`
  mutation sendPasswordResetLink($email: String!) {
    SendPasswordResetEmail(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassoword($token: String!, $password: String!) {
    ResetPassword(token: $token, password: $password)
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($input: LoginInput!) {
    Login(input: $input) {
      token
    }
  }
`;
export const SAVE_APPLICATION_PART1 = gql`
  mutation saveApplicationPart1($input: ApplicationPart1Input) {
    SaveApplicationParts(applicationPart1: $input) {
      id
    }
  }
`;

export const SAVE_APPLICATION_PART2 = gql`
  mutation saveApplicationPart2(
    $emp_present_details: EmploymentPresentDetailsInput
    $emp_details: [EmploymentDetailsInput!]
    $edu_details: [EducationDetailsInput!]!
    $publications: [PublicationDetailsInput!]
  ) {
    SaveApplicationParts(
      applicationPart2: {
        employmentPresentDetails: $emp_present_details
        employmentDetails: $emp_details
        educationDetails: $edu_details
        publicationDetails: $publications
      }
    ) {
      id
    }
  }
`;

export const SAVE_APPLICATION_PART3 = gql`
  mutation saveApplicationPart3(
    $pref1: StudyPref!
    $pref2: StudyPref
    $pref3: StudyPref
    $pref4: StudyPref
    $pref5: StudyPref
  ) {
    SaveApplicationParts(
      applicationPart3: {
        pref1: $pref1
        pref2: $pref2
        pref3: $pref3
        pref4: $pref4
        pref5: $pref5
      }
    ) {
      id
    }
  }
`;

export const SAVE_APPLICATION_PART4 = gql`
  mutation saveApplicationPart4(
    $birthCertificate: Upload
    $degreeCertificates: [Upload!]
    $nic: Upload
    $payment: Upload
    $transcripts: [Upload!]
  ) {
    SaveApplicationParts(
      applicationPart4: {
        birthCertificate: $birthCertificate
        degreeCertificates: $degreeCertificates
        nic: $nic
        payment: $payment
        transcripts: $transcripts
      }
    ) {
      id
    }
  }
`;

export const SAVE_APPLICATION_PART5 = gql`
  mutation saveApplicationPart5(
    $referee1: RefereeDetailsInput!
    $referee2: RefereeDetailsInput!
    $acceptDeclaration: Boolean!
  ) {
    SaveApplicationParts(
      applicationPart5: {
        referee1: $referee1
        referee2: $referee2
        acceptDeclaration: $acceptDeclaration
      }
    ) {
      id
    }
  }
`;

export const REQUEST_EDIT = gql`
  mutation requestEdit($applicationId: ID!) {
    RequestEdit(applicationId: $applicationId) {
      editable
      id
    }
  }
`;

export const APPLICANT_PROCEED_NEXT = gql`
  mutation proceedNext($interviewId: ID!, $enable: Boolean!) {
    ProceedNextPref(interviewId: $interviewId, enable: $enable) {
      id
      proceedNext
    }
  }
`;

export const ADD_PAYMENT_PROOF_SLIP = gql`
  mutation AddPaymentProof($input: PaymentProofInput!) {
    AddPaymentProof(input: $input) {
      id
      paymentProof {
        amount
        bankName
        date
        paymentSlipPath
      }
    }
  }
`;

export const SHORTLIST_APPLICANT = gql`
  mutation shortlist($applicationId: ID!, $selected: Boolean!) {
    SelectApplicantFirstRound(
      applicationId: $applicationId
      selected: $selected
    ) {
      applicationStatus
      id
    }
  }
`;

export const SCHEDULE_INTERVIEW = gql`
  mutation scheduleInterview(
    $applicationId: ID!
    $coordinatorCourse: PrefCourses!
    $date: Timestamp!
    $time: String!
    $panel: String!
    $notes: String
  ) {
    ScheduleInterview(
      input: {
        applicationId: $applicationId
        coordinatorCourse: $coordinatorCourse
        date: $date
        notes: $notes
        panel: $panel
        time: $time
      }
    ) {
      id
      time
      date
      notes
      panel
    }
  }
`;

export const UPDATE_SCHEDULE_INTERVIEW = gql`
  mutation UpdateScheduleInterview(
    $interviewId: ID!
    $coordinatorCourse: PrefCourses!
    $date: Timestamp!
    $time: String!
    $panel: String!
    $notes: String
  ) {
    UpdateScheduleInterview(
      input: {
        interviewId: $interviewId
        coordinatorCourse: $coordinatorCourse
        date: $date
        notes: $notes
        panel: $panel
        time: $time
      }
    ) {
      id
      time
      date
      notes
      panel
    }
  }
`;

export const SUBMIT_MARKS = gql`
  mutation submitMarks($interviewId: ID!, $marks: Int!) {
    SubmitMarks(input: { interviewId: $interviewId, marks: $marks }) {
      id
      marks
      status
    }
  }
`;

export const SUBMIT_MARKS_MULTIPLE = gql`
  mutation submitMarksMultiple($marksArray: [SubmitMarksInput!]!) {
    SubmitMarksMultiple(input: $marksArray) {
      id
      marks
      status
    }
  }
`;

export const REJECT_INTERVIEW = gql`
  mutation rejectInterview($interviewId: ID!) {
    RejectInterview(interviewId: $interviewId) {
      id
      status
    }
  }
`;

export const SELECT_INTERVIEW = gql`
  mutation selectFromInterview($interviewId: ID!) {
    SelectFromInterview(interviewId: $interviewId) {
      id
      status
    }
  }
`;

export const ADD_TO_WAITING_LIST = gql`
  mutation addToWaitingList($interviewId: ID!) {
    AddToWaitingList(interviewId: $interviewId) {
      id
      status
    }
  }
`;

export const SELECT_FINAL = gql`
  mutation selecetFinal($applicationId: ID!) {
    SelectFinal(applicationId: $applicationId) {
      id
      applicationStatus
    }
  }
`;

export const REJECT_FINAL = gql`
  mutation rejectFinal($applicationId: ID!) {
    RejectFinal(applicationId: $applicationId) {
      id
      applicationStatus
    }
  }
`;

export const SUBMIT_REFEREE_REPORT = gql`
  mutation submitRefreeReport($input: RefereeReportInput!) {
    SubmitRefereeReport(input: $input) {
      success
    }
  }
`;

//

export const GENERATE_REGISTRATION_NUMBERS = gql`
  mutation genRegNo($course: PrefCourses!) {
    GenerateRegistrationNumbers(course: $course)
  }
`;
//Emails

export const EMAIL_SEND_SHORTLISTED = gql`
  mutation sendEmail($applicationId: ID!) {
    SendShortListEmail(applicationId: $applicationId)
  }
`;

export const EMAIL_SEND_NOT_SHORTLISTED = gql`
  mutation sendEmail($applicationId: ID!) {
    SendNotShortListEmail(applicationId: $applicationId)
  }
`;

export const EMAIL_SEND_INTERVIEW_SCHEDULED = gql`
  mutation sendEmail($applicationId: ID!) {
    SendInterviewScheduledEmail(applicationId: $applicationId)
  }
`;

export const EMAIL_SEND_INAUGURATION = gql`
  mutation sendEmail(
    $date: Timestamp!
    $location: String!
    $notes: String
    $time: String!
  ) {
    SendInaugurationEmail(
      input: { date: $date, location: $location, notes: $notes, time: $time }
    ){
      date
      time
      location
      notes
    }
  }
`;

export const SET_FEE = gql `
    mutation setFee($type:FeeType!,$amount:Int!){
      SetFees(input: {type: $type,amount: $amount}){
        id
        type
        amount
      }
    }
`

export const ADD_COORDINATOR = gql`
  mutation addCoordinator($input: AddCoordinatorInput!) {
    AddCoordinator(input: $input)
  }
`;

export const REMOVE_COORDINATOR = gql`
  mutation removeCoordinator($userId: ID!) {
    RemoveCoordinator(userId: $userId)
  }
`;

export const ADD_ADMIN = gql`
  mutation addAdmin($input: AddAdminInput!) {
    AddAdmin(input: $input)
  }
`;

export const REMOVE_ADMIN = gql`
  mutation removeAdmin($userId: ID!) {
    RemoveAdmin(userId: $userId)
  }
`;

export const SET_DEADLINE = gql`
  mutation setDeadline($deadline: Timestamp!) {
    SetDeadline(input: { deadline: $deadline })
  }
`;

export const SET_PAYMENT_DEADLINE = gql`
  mutation setPaymentDeadline($deadline: Timestamp!) {
    SetPaymentDeadline(input: { deadline: $deadline })
  }
`;


export const SET_CURRENT_APPLICATION_YEAR = gql`
  mutation setApplicationYear($year: Int!) {
    SetCurrentApplicationYear(year: $year)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($name: String!) {
    UpdateProfile(input: { name: $name }) {
      id
      name
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    ChangePassword(
      input: { password: $newPassword, currentPassword: $currentPassword }
    )
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($token: String!) {
    RefreshToken(
      token: $token
    ){
      token
    }
  }
`;

