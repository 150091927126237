import { PrefCourses, PrefDegreeLevel } from "../graphql/graphql";

/* * * * * * * * Start of Preferences Values * * * * * * * *
    1 - Geotechnical Engineering
    2 - Highway and Traffic Engineering
    3 - Structural Engineering
    4 - Sustainable Built Environment
    5 - Environmental and Water Engineering
* * * * * * * * End of Preferences Values * * * * * * * */

export const mapCourseToGraphqlEnum = (
  courseValue: string
): PrefCourses | null => {
  switch (courseValue) {
    case "1":
      return PrefCourses.GeoTechnical;
    case "2":
      return PrefCourses.Highway;
    case "3":
      return PrefCourses.Structural;
    case "4":
      return PrefCourses.SustainableBuilt;
    case "5":
      return PrefCourses.Water;
    default:
      return null;
  }
};

export const mapGraphqlEnumToCourse = (courseValue: PrefCourses): string => {
  switch (courseValue) {
    case PrefCourses.GeoTechnical:
      return "1";
    case PrefCourses.Highway:
      return "2";
    case PrefCourses.Structural:
      return "3";
    case PrefCourses.SustainableBuilt:
      return "4";
    case PrefCourses.Water:
      return "5";
    default:
      throw Error("Invalid value");
  }
};
/* * * * * * * * Start of Courses Values * * * * * * * *
    1 - PGDip
    2 - MSc.Eng. (L9)
    3 - MSc.Eng. (L10)
    4 - MSc. (L9)
    5 - MSc. (L10)
* * * * * * * * End of Courses Values * * * * * * * */

export const mapDegreeLevelToGraphqlEnum = (
  degreeLevel: string
): PrefDegreeLevel | null => {
  switch (degreeLevel) {
    case "1":
      return PrefDegreeLevel.PgDip;
    case "2":
      return PrefDegreeLevel.MscEngL9;
    case "3":
      return PrefDegreeLevel.MscEngL10;
    case "4":
      return PrefDegreeLevel.MscL9;
    case "5":
      return PrefDegreeLevel.MscL10;
    default:
      return null;
  }
};

export const mapGraphqlEnumToDegreeLevel = (
  degreeLevel: PrefDegreeLevel
): string => {
  switch (degreeLevel) {
    case PrefDegreeLevel.PgDip:
      return "1";
    case PrefDegreeLevel.MscEngL9:
      return "2";
    case PrefDegreeLevel.MscEngL10:
      return "3";
    case PrefDegreeLevel.MscL9:
      return "4";
    case PrefDegreeLevel.MscL10:
      return "5";
    default:
      throw Error("Invalid value");
  }
};
