import { useMutation } from "@apollo/client";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInputRadio,
  CInvalidFeedback,
  CLabel,
  CRow,
  CSelect
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SAVE_APPLICATION_PART3 } from "../../../../graphql/mutations";
import { GET_MY_APPLICATION } from "../../../../graphql/queries";
import {
  mapCourseToGraphqlEnum,
  mapDegreeLevelToGraphqlEnum,
  mapGraphqlEnumToCourse,
  mapGraphqlEnumToDegreeLevel
} from "../../../../util/helpers";
import { notifyErrorToast } from "../../../utils/misc";

let tempCoursePrefs = ["0", "0", "0", "0", "0"];
/* * * * * * * * Start of Preferences Values * * * * * * * *
    1 - Geotechnical Engineering
    2 - Highway and Traffic Engineering
    3 - Structural Engineering
    4 - Sustainable Built Environment
    5 - Environmental and Water Engineering
* * * * * * * * End of Preferences Values * * * * * * * */

let tempDataCoursePrefs = ["0", "0", "0", "0", "0"];
let tempDataLevelPrefs = ["0", "0", "0", "0", "0"];

let tempLevelPrefs = ["0", "0", "0", "0", "0"];
/* * * * * * * * Start of Courses Values * * * * * * * *
    1 - PGDip (SLQF - L8)
    2 - MSc.Eng. (L9)
    3 - MSc.Eng. (SLQF - L10)
    4 -MSc. (SLQF - L9)
    5 -MSc. (SLQF - L10)
* * * * * * * * End of Courses Values * * * * * * * */

const ProgramOfStudy = ({ isDisabled, onStepChange, applicationData }) => {
  const [saveApplicationPart3, { data, loading, called }] = useMutation(
    SAVE_APPLICATION_PART3,
    {
      refetchQueries: [{ query: GET_MY_APPLICATION }],
    }
  );

  const [selectedCourses, setSelectedCourses] = useState(tempCoursePrefs);
  const [selectedDegreeLevel, setSelectedDegreeLevel] = useState(
    tempLevelPrefs
  );

  const [fakeState, setFakeState] = useState("-1"); //to rerender components
  const [fakeState1, setFakeState1] = useState("-1"); //to rerender components
  const [fakeState2, setFakeState2] = useState("-1"); //to rerender components
  const [fakeState3, setFakeState3] = useState("-1"); //to rerender components

  const [isValid, setIsValid] = useState(true);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  useEffect(() => {
    tempLevelPrefs = ["0", "0", "0", "0", "0"];
    setSelectedDegreeLevel(tempLevelPrefs);
  }, []);

  useEffect(() => {
    if (applicationData && applicationData.pref1) {
      setIsDataAvailable(true);

      tempDataCoursePrefs[0] = mapGraphqlEnumToCourse(
        applicationData.pref1.course
      );

      tempDataLevelPrefs[0] = mapGraphqlEnumToDegreeLevel(
        applicationData.pref1.level
      );

      if (applicationData.pref2) {
        tempDataCoursePrefs[1] = mapGraphqlEnumToCourse(
          applicationData.pref2.course
        );

        tempDataLevelPrefs[1] = mapGraphqlEnumToDegreeLevel(
          applicationData.pref2.level
        );
      }

      if (applicationData.pref3) {
        tempDataCoursePrefs[2] = mapGraphqlEnumToCourse(
          applicationData.pref3.course
        );

        tempDataLevelPrefs[2] = mapGraphqlEnumToDegreeLevel(
          applicationData.pref3.level
        );
      }

      if (applicationData.pref4) {
        tempDataLevelPrefs[3] = mapGraphqlEnumToDegreeLevel(
          applicationData.pref4.level
        );

        tempDataCoursePrefs[3] = mapGraphqlEnumToCourse(
          applicationData.pref4.course
        );
      }

      if (applicationData.pref5) {
        tempDataCoursePrefs[4] = mapGraphqlEnumToCourse(
          applicationData.pref5.course
        );

        tempDataLevelPrefs[4] = mapGraphqlEnumToDegreeLevel(
          applicationData.pref5.level
        );
      }

      setSelectedCourses(tempDataCoursePrefs);
      setSelectedDegreeLevel(tempDataLevelPrefs);
    }
  }, [applicationData]);

  function onSelect(index, value) {
    //For dropdowns
    tempCoursePrefs[index] = value; //add value if taken
    setSelectedCourses(tempCoursePrefs);
    setIsValid(true);

    tempLevelPrefs[index] = "0";
    setSelectedDegreeLevel(tempLevelPrefs);

    if (fakeState === value) {
      setFakeState1(value);

      if (fakeState === fakeState1) setFakeState1("-1");
    }

    setFakeState(value);
  }

  function onChange(index, value) {
    //For radio buttons
    tempLevelPrefs[index] = value; //add value if taken
    setSelectedDegreeLevel(tempLevelPrefs);
    setIsValid(true);

    setFakeState2(value);
    setFakeState3(Math.random());
    console.log(selectedDegreeLevel);
  }

  function validate() {
    let found0 = false;

    setFakeState("10");

    if (selectedCourses[0] === "0" || selectedDegreeLevel[0] === "0") {
      setIsValid(false);
      return;
    }

    for (let i = 1; i < 5; i++) {
      if (selectedCourses[i] === "0") {
        found0 = true;
        continue;
      }

      if (
        (found0 && selectedCourses[i] !== "0") ||
        (selectedCourses[i] !== "0" && selectedDegreeLevel[i] === "0")
      ) {
        setIsValid(false);
        return;
      }
    }

    // console.log(selectedDegreeLevel);
    // console.log(selectedCourses);

    let graphqlVariables = {
      pref1: {
        course: mapCourseToGraphqlEnum(selectedCourses[0]),
        level: mapDegreeLevelToGraphqlEnum(selectedDegreeLevel[0]),
      },
      pref2: mapCourseToGraphqlEnum(selectedCourses[1])
        ? {
            course: mapCourseToGraphqlEnum(selectedCourses[1]),
            level: mapDegreeLevelToGraphqlEnum(selectedDegreeLevel[1]),
          }
        : null,
      pref3: mapCourseToGraphqlEnum(selectedCourses[2])
        ? {
            course: mapCourseToGraphqlEnum(selectedCourses[2]),
            level: mapDegreeLevelToGraphqlEnum(selectedDegreeLevel[2]),
          }
        : null,
      pref4: mapCourseToGraphqlEnum(selectedCourses[3])
        ? {
            course: mapCourseToGraphqlEnum(selectedCourses[3]),
            level: mapDegreeLevelToGraphqlEnum(selectedDegreeLevel[3]),
          }
        : null,
      pref5: mapCourseToGraphqlEnum(selectedCourses[4])
        ? {
            course: mapCourseToGraphqlEnum(selectedCourses[4]),
            level: mapDegreeLevelToGraphqlEnum(selectedDegreeLevel[4]),
          }
        : null,
    };

    saveApplicationPart3({ variables: graphqlVariables })
      .then((result) => {
        onStepChange(4);
      })
      .catch((err) => {
        notifyErrorToast("Error saving!");
      });
  }

  // @ts-ignore
  return (
    <>
      <ToastContainer />
      <CCard className="custom-card">
        <CCardHeader className="custom-card-header">
          Programme of Study (Enter 1-5 in the order of preference, if
          applicable)
        </CCardHeader>

        <CCardBody>
          <form className="needs-validation" noValidate>
            <CRow style={{ minHeight: 80, alignContent: "center" }}>
              <CCol xs="12" md="2" xl="2">
                <CLabel htmlFor="nameshort" className="h6 prefTitleBottom10">
                  1st Preference:
                </CLabel>
              </CCol>
              <CCol xs="12" md="4" xl="3">
                <CFormGroup row>
                  <CCol xs="12" md="10">
                    <CSelect
                      custom
                      name="select"
                      id="select"
                      onChange={(event) => onSelect(0, event.target.value)}
                      invalid={selectedCourses[0] === "0" && fakeState !== "-1"}
                      disabled={isDisabled}
                      value={selectedCourses[0]}
                    >
                      {selectedCourses[0] === "0" ? (
                        <option value="0" defaultValue>
                          Select Programme
                        </option>
                      ) : (
                        <option value="0">Change Programme</option>
                      )}
                      {selectedCourses[0] === "1" ? (
                        <option value="1" defaultValue>
                          Geotechnical Engineering
                        </option>
                      ) : selectedCourses.includes("1") ? (
                        <></>
                      ) : (
                        <option value="1">Geotechnical Engineering</option>
                      )}
                      {selectedCourses[0] === "2" ? (
                        <option value="2" defaultValue>
                          Highway and Traffic Engineering
                        </option>
                      ) : selectedCourses.includes("2") ? (
                        <></>
                      ) : (
                        <option value="2">
                          Highway and Traffic Engineering
                        </option>
                      )}
                      {selectedCourses[0] === "3" ? (
                        <option value="3" defaultValue>
                          Structural Engineering
                        </option>
                      ) : selectedCourses.includes("3") ? (
                        <></>
                      ) : (
                        <option value="3">Structural Engineering</option>
                      )}
                      {selectedCourses[0] === "4" ? (
                        <option value="4" defaultValue>
                          Sustainable Built Environment
                        </option>
                      ) : selectedCourses.includes("4") ? (
                        <></>
                      ) : (
                        <option value="4">Sustainable Built Environment</option>
                      )}
                      {selectedCourses[0] === "5" ? (
                        <option value="5" defaultValue>
                          Environmental and Water Engineering
                        </option>
                      ) : selectedCourses.includes("5") ? (
                        <></>
                      ) : (
                        <option value="5">
                          Environmental and Water Engineering
                        </option>
                      )}
                    </CSelect>
                    <CInvalidFeedback>
                      First preference is mandatory
                    </CInvalidFeedback>
                  </CCol>
                </CFormGroup>
              </CCol>
              <CCol xs="12" md="7" xl="7">
                <CRow>
                  {selectedCourses[0] !== "4" && selectedCourses[0] !== "0" ? (
                    <>
                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio1"
                          name="inline-radios1"
                          value="PGDip"
                          onChange={() => onChange(0, "1")}
                          invalid={
                            selectedCourses[0] !== "0" &&
                            selectedDegreeLevel[0] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[0] === "1"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio1"
                        >
                          PGDip (SLQF-L8)
                        </CLabel>
                      </CFormGroup>
                    </CCol>

                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio2"
                          name="inline-radios1"
                          value="MScEngL9"
                          onChange={() => onChange(0, "2")}
                          invalid={
                            selectedCourses[0] !== "0" &&
                            selectedDegreeLevel[0] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[0] === "2"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio2"
                        >
                          MEng (SLQF-L9)
                        </CLabel>
                      </CFormGroup>
                    </CCol>

                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio3"
                          name="inline-radios1"
                          value="MScEngL10"
                          onChange={() => onChange(0, "3")}
                          invalid={
                            selectedCourses[0] !== "0" &&
                            selectedDegreeLevel[0] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[0] === "3"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio3"
                        >
                          MScEng (SLQF-L10)
                        </CLabel>
                      </CFormGroup>
                    </CCol>
                  </>
                  ) : (
                    <>
                      {selectedCourses[0] === "4" ? (
                        <>
                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio4"
                              name="inline-radios1"
                              value="PGDip"
                              onChange={() => onChange(0, "1")}
                              invalid={
                                selectedCourses[0] !== "0" &&
                                selectedDegreeLevel[0] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[0] === "1"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio4"
                            >
                              PGDip (SLQF-L8)
                            </CLabel>
                          </CFormGroup>
                        </CCol>

                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio5"
                              name="inline-radios1"
                              value="MScL9"
                              onChange={() => onChange(0, "4")}
                              invalid={
                                selectedCourses[0] !== "0" &&
                                selectedDegreeLevel[0] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[0] === "4"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio5"
                            >
                              Masters (SLQF-L9)
                            </CLabel>
                          </CFormGroup>
                        </CCol>

                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio6"
                              name="inline-radios1"
                              value="MScL10"
                              onChange={() => onChange(0, "5")}
                              invalid={
                                selectedCourses[0] !== "0" &&
                                selectedDegreeLevel[0] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[0] === "5"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio6"
                            >
                              MSc (SLQF-L10)
                            </CLabel>
                        </CFormGroup>
                      </CCol>
                      </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </CRow>
              </CCol>
            </CRow>

            <CRow style={{ minHeight: 80, alignContent: "center" }}>
              <CCol xs="12" md="2" xl="2">
                <CLabel
                  htmlFor="nameshort"
                  className="h6 prefTitleTop50 prefTitleBottom10"
                >
                  2nd Preference:
                </CLabel>
              </CCol>
              <CCol xs="12" md="4" xl="3">
                <CFormGroup row>
                  <CCol xs="12" md="10">
                    {selectedCourses[0] === "0" &&
                    selectedCourses[1] === "0" ? (
                      <CSelect custom disabled>
                        <option value="0" defaultValue>
                          Please select your 1st preference
                        </option>
                      </CSelect>
                    ) : (
                      <CSelect
                        custom
                        name="select"
                        id="select"
                        onChange={(event) => onSelect(1, event.target.value)}
                        invalid={
                          selectedCourses[1] === "0" &&
                          (selectedCourses[2] !== "0" ||
                            selectedCourses[3] !== "0" ||
                            selectedCourses[4] !== "0")
                        }
                        disabled={isDisabled}
                        value={selectedCourses[1]}
                      >
                        {selectedCourses[1] === "0" ? (
                          <option value="0" defaultValue>
                            Select Programme
                          </option>
                        ) : (
                          <option value="0">Change Programme</option>
                        )}
                        {selectedCourses[1] === "1" ? (
                          <option value="1" defaultValue>
                            Geotechnical Engineering
                          </option>
                        ) : selectedCourses.includes("1") ? (
                          <></>
                        ) : (
                          <option value="1">Geotechnical Engineering</option>
                        )}
                        {selectedCourses[1] === "2" ? (
                          <option value="2" defaultValue>
                            Highway and Traffic Engineering
                          </option>
                        ) : selectedCourses.includes("2") ? (
                          <></>
                        ) : (
                          <option value="2">
                            Highway and Traffic Engineering
                          </option>
                        )}
                        {selectedCourses[1] === "3" ? (
                          <option value="3" defaultValue>
                            Structural Engineering
                          </option>
                        ) : selectedCourses.includes("3") ? (
                          <></>
                        ) : (
                          <option value="3">Structural Engineering</option>
                        )}
                        {selectedCourses[1] === "4" ? (
                          <option value="4" defaultValue>
                            Sustainable Built Environment
                          </option>
                        ) : selectedCourses.includes("4") ? (
                          <></>
                        ) : (
                          <option value="4">
                            Sustainable Built Environment
                          </option>
                        )}
                        {selectedCourses[1] === "5" ? (
                          <option value="5" defaultValue>
                            Environmental and Water Engineering
                          </option>
                        ) : selectedCourses.includes("5") ? (
                          <></>
                        ) : (
                          <option value="5">
                            Environmental and Water Engineering
                          </option>
                        )}
                      </CSelect>
                    )}
                    <CInvalidFeedback>
                      Please select preferences in the correct order
                    </CInvalidFeedback>
                  </CCol>
                </CFormGroup>
              </CCol>
              <CCol xs="12" md="7" xl="7">
                <CRow>
                  {selectedCourses[1] !== "4" && selectedCourses[1] !== "0" ? (
                    <>
                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio7"
                          name="inline-radios2"
                          value="PGDip"
                          onChange={() => onChange(1, "1")}
                          invalid={
                            selectedCourses[1] !== "0" &&
                            selectedDegreeLevel[1] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[1] === "1"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio7"
                        >
                          PGDip (SLQF-L8)
                        </CLabel>
                      </CFormGroup>
                    </CCol>

                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio8"
                          name="inline-radio2"
                          value="MScEngL9"
                          onChange={() => onChange(1, "2")}
                          invalid={
                            selectedCourses[1] !== "0" &&
                            selectedDegreeLevel[1] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[1] === "2"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio8"
                        >
                          MEng (SLQF-L9)
                        </CLabel>
                      </CFormGroup>
                    </CCol>

                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio9"
                          name="inline-radios2"
                          value="MScEngL10"
                          onChange={() => onChange(1, "3")}
                          invalid={
                            selectedCourses[1] !== "0" &&
                            selectedDegreeLevel[1] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[1] === "3"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio9"
                        >
                          MScEng (SLQF-L10)
                        </CLabel>
                      </CFormGroup>
                    </CCol>
                  </>
                  ) : (
                    <>
                      {selectedCourses[1] === "4" ? (
                        <>
                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio10"
                              name="inline-radios2"
                              value="PGDip"
                              onChange={() => onChange(1, "1")}
                              invalid={
                                selectedCourses[1] !== "0" &&
                                selectedDegreeLevel[1] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[1] === "1"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio10"
                            >
                              PGDip (SLQF-L8)
                            </CLabel>
                          </CFormGroup>
                        </CCol>

                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio11"
                              name="inline-radios2"
                              value="MScL9"
                              onChange={() => onChange(1, "4")}
                              invalid={
                                selectedCourses[1] !== "0" &&
                                selectedDegreeLevel[1] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[1] === "4"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio11"
                            >
                              Masters (SLQF-L9)
                            </CLabel>
                          </CFormGroup>
                        </CCol>

                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio12"
                              name="inline-radios2"
                              value="MScL10"
                              onChange={() => onChange(1, "5")}
                              invalid={
                                selectedCourses[1] !== "0" &&
                                selectedDegreeLevel[1] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[1] === "5"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio12"
                            >
                              MSc (SLQF-L10)
                            </CLabel>
                        </CFormGroup>
                      </CCol>
                      </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </CRow>
              </CCol>
            </CRow>

            <CRow style={{ minHeight: 80, alignContent: "center" }}>
              <CCol xs="12" md="2" xl="2">
                <CLabel htmlFor="nameshort" className="h6 prefTitleTop50">
                  3rd Preference:
                </CLabel>
              </CCol>
              <CCol xs="12" md="4" xl="3">
                <CFormGroup row>
                  <CCol xs="12" md="10">
                    {selectedCourses[1] === "0" &&
                    selectedCourses[2] === "0" ? (
                      <CSelect custom disabled>
                        <option value="0" defaultValue>
                          Please select your 2nd preference
                        </option>
                      </CSelect>
                    ) : (
                      <CSelect
                        custom
                        name="select"
                        id="select"
                        onChange={(event) => onSelect(2, event.target.value)}
                        invalid={
                          selectedCourses[2] === "0" &&
                          (selectedCourses[3] !== "0" ||
                            selectedCourses[4] !== "0")
                        }
                        disabled={isDisabled}
                        value={selectedCourses[2]}
                      >
                        {selectedCourses[2] === "0" ? (
                          <option value="0" defaultValue>
                            Select Programme
                          </option>
                        ) : (
                          <option value="0">Change Programme</option>
                        )}
                        {selectedCourses[2] === "1" ? (
                          <option value="1" defaultValue>
                            Geotechnical Engineering
                          </option>
                        ) : selectedCourses.includes("1") ? (
                          <></>
                        ) : (
                          <option value="1">Geotechnical Engineering</option>
                        )}
                        {selectedCourses[2] === "2" ? (
                          <option value="2" defaultValue>
                            Highway and Traffic Engineering
                          </option>
                        ) : selectedCourses.includes("2") ? (
                          <></>
                        ) : (
                          <option value="2">
                            Highway and Traffic Engineering
                          </option>
                        )}
                        {selectedCourses[2] === "3" ? (
                          <option value="3" defaultValue>
                            Structural Engineering
                          </option>
                        ) : selectedCourses.includes("3") ? (
                          <></>
                        ) : (
                          <option value="3">Structural Engineering</option>
                        )}
                        {selectedCourses[2] === "4" ? (
                          <option value="4" defaultValue>
                            Sustainable Built Environment
                          </option>
                        ) : selectedCourses.includes("4") ? (
                          <></>
                        ) : (
                          <option value="4">
                            Sustainable Built Environment
                          </option>
                        )}
                        {selectedCourses[2] === "5" ? (
                          <option value="5" defaultValue>
                            Environmental and Water Engineering
                          </option>
                        ) : selectedCourses.includes("5") ? (
                          <></>
                        ) : (
                          <option value="5">
                            Environmental and Water Engineering
                          </option>
                        )}
                      </CSelect>
                    )}
                    <CInvalidFeedback>
                      Please select preferences in the correct order
                    </CInvalidFeedback>
                  </CCol>
                </CFormGroup>
              </CCol>
              <CCol xs="12" md="7" xl="7">
                <CRow>
                  {selectedCourses[2] !== "4" && selectedCourses[2] !== "0" ? (
                    <>
                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio13"
                          name="inline-radios3"
                          value="PGDip"
                          onChange={() => onChange(2, "1")}
                          invalid={
                            selectedCourses[2] !== "0" &&
                            selectedDegreeLevel[2] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[2] === "1"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio13"
                        >
                          PGDip (SLQF-L8)
                        </CLabel>
                      </CFormGroup>
                    </CCol>

                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio14"
                          name="inline-radios3"
                          value="MScEngL9"
                          onChange={() => onChange(2, "2")}
                          invalid={
                            selectedCourses[2] !== "0" &&
                            selectedDegreeLevel[2] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[2] === "2"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio14"
                        >
                          MEng (SLQF-L9)
                        </CLabel>
                      </CFormGroup>
                    </CCol>

                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio15"
                          name="inline-radios3"
                          value="MScEngL10"
                          onChange={() => onChange(2, "3")}
                          invalid={
                            selectedCourses[2] !== "0" &&
                            selectedDegreeLevel[2] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[2] === "3"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio15"
                        >
                          MScEng (SLQF-L10)
                        </CLabel>
                      </CFormGroup>
                    </CCol>
                  </>
                  ) : (
                    <>
                      {selectedCourses[2] === "4" ? (
                        <>
                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio16"
                              name="inline-radios3"
                              value="PGDip"
                              onChange={() => onChange(2, "1")}
                              invalid={
                                selectedCourses[2] !== "0" &&
                                selectedDegreeLevel[2] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[2] === "1"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio16"
                            >
                              PGDip (SLQF-L8)
                            </CLabel>
                          </CFormGroup>
                        </CCol>

                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio17"
                              name="inline-radios3"
                              value="MScL9"
                              onChange={() => onChange(2, "4")}
                              invalid={
                                selectedCourses[2] !== "0" &&
                                selectedDegreeLevel[2] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[2] === "4"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio17"
                            >
                              Masters (SLQF-L9)
                            </CLabel>
                          </CFormGroup>
                        </CCol>

                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio18"
                              name="inline-radios3"
                              value="MScL10"
                              onChange={() => onChange(2, "5")}
                              invalid={
                                selectedCourses[2] !== "0" &&
                                selectedDegreeLevel[2] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[2] === "5"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio18"
                            >
                              MSc (SLQF-L10)
                            </CLabel>
                        </CFormGroup>
                      </CCol>
                      </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </CRow>
              </CCol>
            </CRow>

            <CRow style={{ minHeight: 80, alignContent: "center" }}>
              <CCol xs="12" md="2" xl="2">
                <CLabel htmlFor="nameshort" className="h6 prefTitleTop50">
                  4th Preference:
                </CLabel>
              </CCol>
              <CCol xs="12" md="4" xl="3">
                <CFormGroup row>
                  <CCol xs="12" md="10">
                    {selectedCourses[2] === "0" &&
                    selectedCourses[3] === "0" ? (
                      <CSelect custom disabled>
                        <option value="0" defaultValue>
                          Please select your 3rd preference
                        </option>
                      </CSelect>
                    ) : (
                      <CSelect
                        custom
                        name="select"
                        id="select"
                        onChange={(event) => onSelect(3, event.target.value)}
                        invalid={
                          selectedCourses[3] === "0" &&
                          selectedCourses[4] !== "0"
                        }
                        value={selectedCourses[3]}
                        disabled={isDisabled}
                      >
                        {selectedCourses[3] === "0" ? (
                          <option value="0" defaultValue>
                            Select Programme
                          </option>
                        ) : (
                          <option value="0">Change Programme</option>
                        )}
                        {selectedCourses[3] === "1" ? (
                          <option value="1" defaultValue>
                            Geotechnical Engineering
                          </option>
                        ) : selectedCourses.includes("1") ? (
                          <></>
                        ) : (
                          <option value="1">Geotechnical Engineering</option>
                        )}
                        {selectedCourses[3] === "2" ? (
                          <option value="2" defaultValue>
                            Highway and Traffic Engineering
                          </option>
                        ) : selectedCourses.includes("2") ? (
                          <></>
                        ) : (
                          <option value="2">
                            Highway and Traffic Engineering
                          </option>
                        )}
                        {selectedCourses[3] === "3" ? (
                          <option value="3" defaultValue>
                            Structural Engineering
                          </option>
                        ) : selectedCourses.includes("3") ? (
                          <></>
                        ) : (
                          <option value="3">Structural Engineering</option>
                        )}
                        {selectedCourses[3] === "4" ? (
                          <option value="4" defaultValue>
                            Sustainable Built Environment
                          </option>
                        ) : selectedCourses.includes("4") ? (
                          <></>
                        ) : (
                          <option value="4">
                            Sustainable Built Environment
                          </option>
                        )}
                        {selectedCourses[3] === "5" ? (
                          <option value="5" defaultValue>
                            Environmental and Water Engineering
                          </option>
                        ) : selectedCourses.includes("5") ? (
                          <></>
                        ) : (
                          <option value="5">
                            Environmental and Water Engineering
                          </option>
                        )}
                      </CSelect>
                    )}
                    <CInvalidFeedback>
                      Please select preferences in the correct order
                    </CInvalidFeedback>
                  </CCol>
                </CFormGroup>
              </CCol>
              <CCol xs="12" md="7" xl="7">
                <CRow>
                  {selectedCourses[3] !== "4" && selectedCourses[3] !== "0" ? (
                    <>
                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio19"
                          name="inline-radios4"
                          value="PGDip"
                          onChange={() => onChange(3, "1")}
                          invalid={
                            selectedCourses[3] !== "0" &&
                            selectedDegreeLevel[3] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[3] === "1"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio19"
                        >
                          PGDip (SLQF-L8)
                        </CLabel>
                      </CFormGroup>
                    </CCol>

                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio20"
                          name="inline-radios4"
                          value="MScEngL9"
                          onChange={() => onChange(3, "2")}
                          invalid={
                            selectedCourses[3] !== "0" &&
                            selectedDegreeLevel[3] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[3] === "2"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio20"
                        >
                          MEng (SLQF-L9)
                        </CLabel>
                      </CFormGroup>
                    </CCol>

                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio21"
                          name="inline-radios4"
                          value="MScEngL10"
                          onChange={() => onChange(3, "3")}
                          invalid={
                            selectedCourses[3] !== "0" &&
                            selectedDegreeLevel[3] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[3] === "3"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio21"
                        >
                          MScEng (SLQF-L10)
                        </CLabel>
                      </CFormGroup>
                    </CCol>
                  </>
                  ) : (
                    <>
                      {selectedCourses[3] === "4" ? (
                        <>
                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio22"
                              name="inline-radios4"
                              value="PGDip"
                              onChange={() => onChange(3, "1")}
                              invalid={
                                selectedCourses[3] !== "0" &&
                                selectedDegreeLevel[3] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[3] === "1"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio22"
                            >
                              PGDip (SLQF-L8)
                            </CLabel>
                          </CFormGroup>
                        </CCol>

                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio23"
                              name="inline-radios4"
                              value="MScL9"
                              onChange={() => onChange(3, "4")}
                              invalid={
                                selectedCourses[3] !== "0" &&
                                selectedDegreeLevel[3] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[3] === "4"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio23"
                            >
                              Masters (SLQF-L9)
                            </CLabel>
                          </CFormGroup>
                        </CCol>

                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio24"
                              name="inline-radios4"
                              value="MScL10"
                              onChange={() => onChange(3, "5")}
                              invalid={
                                selectedCourses[3] !== "0" &&
                                selectedDegreeLevel[3] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[3] === "5"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio24"
                            >
                              MSc (SLQF-L10)
                            </CLabel>
                        </CFormGroup>
                      </CCol>
                      </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </CRow>
              </CCol>
            </CRow>

            <CRow style={{ minHeight: 80, alignContent: "center" }}>
              <CCol xs="12" md="2" xl="2">
                <CLabel htmlFor="nameshort" className="h6 prefTitleTop50">
                  5th Preference:
                </CLabel>
              </CCol>
              <CCol xs="12" md="4" xl="3">
                <CFormGroup row>
                  <CCol xs="12" md="10">
                    {selectedCourses[3] === "0" &&
                    selectedCourses[4] === "0" ? (
                      <CSelect custom disabled>
                        <option value="0" defaultValue>
                          Please select your 4th preference
                        </option>
                      </CSelect>
                    ) : (
                      <CSelect
                        custom
                        name="select"
                        id="select"
                        onChange={(event) => onSelect(4, event.target.value)}
                        value={selectedCourses[4]}
                        disabled={isDisabled}
                      >
                        {selectedCourses[4] === "0" ? (
                          <option value="0" defaultValue>
                            Select Programme
                          </option>
                        ) : (
                          <option value="0">Change Programme</option>
                        )}
                        {selectedCourses[4] === "1" ? (
                          <option value="1" defaultValue>
                            Geotechnical Engineering
                          </option>
                        ) : selectedCourses.includes("1") ? (
                          <></>
                        ) : (
                          <option value="1">Geotechnical Engineering</option>
                        )}
                        {selectedCourses[4] === "2" ? (
                          <option value="2" defaultValue>
                            Highway and Traffic Engineering
                          </option>
                        ) : selectedCourses.includes("2") ? (
                          <></>
                        ) : (
                          <option value="2">
                            Highway and Traffic Engineering
                          </option>
                        )}
                        {selectedCourses[4] === "3" ? (
                          <option value="3" defaultValue>
                            Structural Engineering
                          </option>
                        ) : selectedCourses.includes("3") ? (
                          <></>
                        ) : (
                          <option value="3">Structural Engineering</option>
                        )}
                        {selectedCourses[4] === "4" ? (
                          <option value="4" defaultValue>
                            Sustainable Built Environment
                          </option>
                        ) : selectedCourses.includes("4") ? (
                          <></>
                        ) : (
                          <option value="4">
                            Sustainable Built Environment
                          </option>
                        )}
                        {selectedCourses[4] === "5" ? (
                          <option value="5" defaultValue>
                            Environmental and Water Engineering
                          </option>
                        ) : selectedCourses.includes("5") ? (
                          <></>
                        ) : (
                          <option value="5">
                            Environmental and Water Engineering
                          </option>
                        )}
                      </CSelect>
                    )}
                    <CInvalidFeedback>
                      Please select preferences in the correct order
                    </CInvalidFeedback>
                  </CCol>
                </CFormGroup>
              </CCol>
              <CCol xs="12" md="7" xl="7">
                <CRow>
                  {selectedCourses[4] !== "4" && selectedCourses[4] !== "0" ? (
                    <>
                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio25"
                          name="inline-radios5"
                          value="PGDip"
                          onChange={() => onChange(4, "1")}
                          invalid={
                            selectedCourses[4] !== "0" &&
                            selectedDegreeLevel[4] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[4] === "1"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio25"
                        >
                          PGDip (SLQF-L8)
                        </CLabel>
                      </CFormGroup>
                    </CCol>

                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio26"
                          name="inline-radios5"
                          value="MScEngL9"
                          onChange={() => onChange(4, "2")}
                          invalid={
                            selectedCourses[4] !== "0" &&
                            selectedDegreeLevel[4] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[4] === "2"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio26"
                        >
                          MEng (SLQF-L9)
                        </CLabel>
                      </CFormGroup>
                    </CCol>

                    <CCol xs="4">
                      <CFormGroup variant="custom-radio" inline>
                        <CInputRadio
                          custom
                          id="inline-radio27"
                          name="inline-radios5"
                          value="MScEngL10"
                          onChange={() => onChange(4, "3")}
                          invalid={
                            selectedCourses[4] !== "0" &&
                            selectedDegreeLevel[4] === "0"
                          }
                          disabled={isDisabled}
                          checked={selectedDegreeLevel[4] === "3"}
                        />
                        <CLabel
                          variant="custom-checkbox"
                          htmlFor="inline-radio27"
                        >
                          MScEng (SLQF-L10)
                        </CLabel>
                      </CFormGroup>
                    </CCol>
                  </>
                  ) : (
                    <>
                      {selectedCourses[4] === "4" ? (
                        <>
                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio28"
                              name="inline-radios5"
                              value="PGDip"
                              onChange={() => onChange(4, "1")}
                              invalid={
                                selectedCourses[4] !== "0" &&
                                selectedDegreeLevel[4] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[4] === "1"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio28"
                            >
                              PGDip (SLQF-L8)
                            </CLabel>
                          </CFormGroup>
                        </CCol>

                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio29"
                              name="inline-radios5"
                              value="MScL9"
                              onChange={() => onChange(4, "4")}
                              invalid={
                                selectedCourses[4] !== "0" &&
                                selectedDegreeLevel[4] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[4] === "4"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio29"
                            >
                              Masters (SLQF-L9)
                            </CLabel>
                          </CFormGroup>
                        </CCol>

                        <CCol xs="4">
                          <CFormGroup variant="custom-radio" inline>
                            <CInputRadio
                              custom
                              id="inline-radio30"
                              name="inline-radios5"
                              value="MScL10"
                              onChange={() => onChange(4, "5")}
                              invalid={
                                selectedCourses[4] !== "0" &&
                                selectedDegreeLevel[4] === "0"
                              }
                              disabled={isDisabled}
                              checked={selectedDegreeLevel[4] === "5"}
                            />
                            <CLabel
                              variant="custom-checkbox"
                              htmlFor="inline-radio30"
                            >
                              MSc (SLQF-L10)
                            </CLabel>
                        </CFormGroup>
                      </CCol>
                      </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </CRow>
              </CCol>
            </CRow>

            <div
              className={!isValid ? "small mb-2" : "d-sm-none mb-2"}
              style={{ color: "#ab0202" }}
            >
              * Please fill out all compulsory fields
            </div>
            <hr />
            <CRow>
              <CCol xs="12" md="12" xl="12" className="d-flex flex-row-reverse">
                {!isDisabled ? (
                  <CButton
                    className="ml-4"
                    // type="submit"
                    size="md"
                    color="primary"
                    onClick={() => validate()}
                  >
                    Next&nbsp;&nbsp;
                    <CIcon name="cil-arrow-right" />{" "}
                  </CButton>
                ) : (
                  <CButton
                    className="ml-4"
                    // type="submit"
                    size="md"
                    color="primary"
                    onClick={() => onStepChange(4)}
                  >
                    Next&nbsp;&nbsp;
                    <CIcon name="cil-arrow-right" />{" "}
                  </CButton>
                )}
                <CButton
                  type="submit"
                  size="md"
                  color="primary"
                  onClick={() => onStepChange(2)}
                >
                  <CIcon name="cil-arrow-left" /> &nbsp;&nbsp;Back
                </CButton>
              </CCol>
            </CRow>
          </form>
        </CCardBody>
      </CCard>
    </>
  );
};

export default ProgramOfStudy;
