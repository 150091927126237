import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import "./scss/style.scss";
import { useLazyQuery } from "@apollo/client";
import { CHECK_TOKEN } from "./graphql/queries";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CCardFooter,
  CContainer
} from "@coreui/react";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayoutApplicant = React.lazy(
  () => import("./containers/TheLayoutApplicant")
);
const TheLayoutCoordinator = React.lazy(
  () => import("./containers/TheLayoutCoordinator")
);
const TheLayoutAdmin = React.lazy(() => import("./containers/TheLayoutAdmin"));
const TheLayoutSuperAdmin = React.lazy(
  () => import("./containers/TheLayoutSuperAdmin")
);

// Pages
const Login = React.lazy(() => import("./views/pages/auth/login/Login"));
const Register = React.lazy(
  () => import("./views/pages/auth/register/Register")
);
const ForgotPassword = React.lazy(
  () => import("./views/pages/auth/forgot_password/ForgotPassword")
);
const ResetPassword = React.lazy(
  () => import("./views/pages/auth/reset_password/ResetPassword")
);

const RefereeReport = React.lazy(
  () => import("./views/pages/referee_report/RefereeReport")
);
const ViewRefereeReport = React.lazy(
  () => import("./views/pages/view_referee_report/ViewRefereeReport")
);

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const Temp  = () => {

  return(
    <>
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer style={{ marginTop: "-3%" }}>
          <div style={{ marginBottom: "2%", textAlign: "center" }}>
            <img
              src="../../logo.png"
              height="85"
              style={{ marginBottom: 15 }}
            />
            <h3 style={{ fontWeight: 300 }}>
              Online Application Management System
            </h3>
            <h3 style={{ fontWeight: 300 }}>
              Department of Civil Engineering - University of Peradeniya
            </h3>
          </div>

          <CRow className="justify-content-center">
            <CCol md="9" lg="7" xl="6">
              <CCard className="mx-4 custom-card">
                <CCardBody className="p-4 text-center">
                  Thank you for the interest. <br/>We will be accepting applications after 24th of October 2021.
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            borderTop: "2px solid #012c7f",
            backgroundColor: "white",
            height: "5%",
            width: "100%",
          }}
        >
          <CCol
            xs="12"
            className="text-right"
            style={{ height: "100%", verticalAlign: "center", paddingTop: 8 }}
          >
            Designed and Developed by{" "}
            <a href="https://revokex.com" target="_blank">
              RevokeX Technology
            </a>
          </CCol>
        </div>
      </div>
    </>
  )
}

const App = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        {/* <Temp></Temp>            */}
        <Switch>
        <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          {/* <Route
            exact
            path="/login_temp"
            name="Login Page"
            render={(props) => <Login {...props} />}
          /> */}
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/forgot"
            name="Page 500"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/reset"
            name="Reset Password"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            exact
            path="/referee"
            name="Referee Report"
            render={(props) => <RefereeReport {...props} />}
          />
          <Route
            exact
            path="/viewReferee"
            name="View Referee Report"
            render={(props) => <ViewRefereeReport {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <PrivateRoutes path="/" name="Home" />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
