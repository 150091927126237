import CIcon from "@coreui/icons-react";
import { CBreadcrumbRouter, CHeader, CHeaderBrand, CHeaderNav, CLink, CSubheader, CToggler } from "@coreui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useHistory, useLocation } from "react-router-dom";
// routes config
import routes from "../../routesAdmin";

const TheHeaderAdmin = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  var title = "";
  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  const getPaths = (pathname) => {
    const paths = ["/"];
    if (pathname === "/") return paths;
    pathname.split("/").reduce((prev, curr) => {
      const currPath = `${prev}/${curr}`;
      paths.push(currPath);
      return currPath;
    });
    return paths;
  };

  let history = useHistory();

  const logout = () => {
    localStorage.clear();
    history.replace("/login");
  };

  let items = null;

  const currPath = useLocation().pathname;
  const paths = getPaths(currPath);
  const currRoutes = paths
    .map((currPath) => {
      const route = routes.find((route) =>
        matchPath(currPath, {
          path: route.path,
          exact: route.exact,
        })
      );
      return { ...route, currPath };
    })
    .filter((route) => route && route.name);

  items = currRoutes.map((route) => {
    title = route.name;
    return route.name;
  });

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto" />

      <CHeaderNav className="px-3">
        <CLink
          onClick={logout}
          style={{ color: "rgba(0,0,0,0.7)", paddingRight: 15 }}
        >
          <CIcon
            name="cilAccountLogout"
            alt="Logout"
            style={{ paddingRight: 3 }}
          />
          &nbsp;Logout
        </CLink>
      </CHeaderNav>
      <CSubheader className="px-3 justify-content-between">
        <div className="d-md-down-none mfe-2 c-subheader-nav">
          <CLink
            className="c-subheader-nav-link"
            href="#"
            style={{ fontWeight: "400", fontSize: 16, marginleft: "0.5rem" }}
          >
            {title}
          </CLink>
        </div>
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        />
      </CSubheader>
    </CHeader>
  );
};

export default TheHeaderAdmin;
