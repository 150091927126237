import { gql } from "@apollo/client";
import {
  ALL_APPLICATION_FIELDS,
  ALL_APPLICATION_FIELDS_PARTIAL,
  FRAGMENT_APPLICATION_TABLE,
  FRAGMENT_INTERVIEW_APPLICANT_DASHBOARD,
  FRAGMENT_INTERVIEW_TABLE
} from "./fragments";

export const GET_PROFILE = gql`
  query GetProfile {
    profile {
      id
      name
      nic
      email
      role
      coordinate
    }
  }
`;

export const GET_MY_APPLICATION = gql`
  ${ALL_APPLICATION_FIELDS_PARTIAL}
  query GetApplication {
    myApplication {
      ...allApplicationFieldsPartial
    }

    profile {
      id
      name
      nic
      email
      role
      coordinate
    }
  }
`;

export const APPLICANT_DASHBOARD = gql`
  ${ALL_APPLICATION_FIELDS_PARTIAL}
  ${FRAGMENT_INTERVIEW_APPLICANT_DASHBOARD}
  query GetMyDashboard {
    profile {
      application {
        ...allApplicationFieldsPartial
      }
    }

    myInterviews {
      ...interviewsApplicant
    }
  }
`;

export const GET_APPLICATION_BY_ID = gql`
  ${ALL_APPLICATION_FIELDS}

  query application($id: ID!) {
    application(id: $id) {
      ...allApplicationFields
    }
  }
`;

export const GET_DASHBOARD_ALL = gql`
  ${FRAGMENT_APPLICATION_TABLE}
  query GetAllApplicationAndDashboard {
    filterApplication {
      ...ApplicationTableFragment
    }

    dashboard {
      total
      rejected
      scheduled
      selected
    }
  }
`;

export const GET_APPLICATIONS_FILTER = gql`
  ${FRAGMENT_APPLICATION_TABLE}
  query GetApplicationWithFilter(
    $course: PrefCourses
    $level: PrefDegreeLevel
    $prefPriority: Int
    $status: ApplicationStatus
    $onlyActivePref: Boolean
  ) {
    filterApplication(
      filter: {
        course: $course
        level: $level
        prefPriority: $prefPriority
        status: $status
        onlyActivePref: $onlyActivePref
      }
    ) {
      ...ApplicationTableFragment
    }
  }
`;

export const GET_APPLICATIONS_PENDING_INTERVIEW_SCHEDULE = gql`
  ${FRAGMENT_APPLICATION_TABLE}
  query GetApplicationsPendingInterviewSchedule($coordinate: PrefCourses!) {
    applicationsPendingInterviewSchedule(coordinate: $coordinate) {
      ...ApplicationTableFragment
    }
  }
`;

export const FILTER_INTERVIEWS = gql`
  ${FRAGMENT_INTERVIEW_TABLE}
  query GetFilteredInterviews(
    $coordinate: PrefCourses!
    $interviewStatus: InterviewStatus!
  ) {
    filterInterviews(
      interviewStatus: $interviewStatus
      coordinate: $coordinate
    ) {
      ...interviewTableData
    }
  }
`;

export const GET_PENDING_INTERVIEWS = gql`
  ${FRAGMENT_INTERVIEW_TABLE}
  query GetPendingInterviews($coordinate: PrefCourses!) {
    interviewsPending(coordinate: $coordinate) {
      ...interviewTableData
    }
  }
`;

export const GET_COMPLETED_INTERVIEWS = gql`
  ${FRAGMENT_INTERVIEW_TABLE}
  query GetCompletedInterviews($coordinate: PrefCourses!) {
    interviewsCompleted(coordinate: $coordinate) {
      ...interviewTableData
    }
  }
`;

export const GET_APPLICANT_DETAILS_REFEREE = gql`
  query get($token: String!) {
    applicantDetailsForReferee(token: $token) {
      name
      degree
      nameReferee
      level
      submitted
    }
  }
`;

export const GET_REFEREE_REPORTS = gql`
  query get($applicationId: ID!) {
    refereeReport(applicationId: $applicationId) {
      report1 {
        name
        title
        institute
        association {
          a
          b
          c
          cYears
          d
          dYears
          e
        }
        comments
        characteristic {
          a
          b
          c
          d
          e
          f
        }
      }

      report2 {
        name
        title
        institute
        association {
          a
          b
          c
          cYears
          d
          dYears
          e
        }
        comments
        characteristic {
          a
          b
          c
          d
          e
          f
        }
      }
    }
  }
`;

export const GET_COORDINATORS = gql`
  query getCoordinators {
    listCoordinators {
      id
      name
      email
      coordinate
      role
    }
  }
`;

export const GET_COORDINATORS_AND_ADMINS = gql`
  query getCoordinatorsAndAdmins {
    listCoordinators {
      id
      name
      email
      coordinate
      role
    }

    listAdmins {
      id
      name
      email
      role
    }

    listSuperAdmins {
      id
      name
      email
      role
    }
  }
`;

export const GET_DEADLINE = gql`
  query getDeadline {
    deadline
  }
`;

export const GET_PAYMENT_DEADLINE = gql`
  query getPaymentDeadline {
    paymentDeadline
  }
`;

export const GET_ALL_DEADLINES = gql`
  query getAllDeadlines {
    deadline
    paymentDeadline
  }
`;

export const GET_FEES = gql`
  query getFees {
    applicationFee:fee(type:Application){
      id
      type
      amount
    }

    registrationFee:fee(type:Registration){
      id
      type
      amount
    }
  }
`;

export const GET_INAUGURATION = gql `
    query inauguration{
      inauguration{
        date
        time
        location
        notes
      }
    }
`

export const GET_APPLICATION_YEAR = gql`
  query getDeadline {
    currentApplicationYear
  }
`;

export const CHECK_TOKEN = gql`
    query checkToken {
      checkToken
    }
  `
