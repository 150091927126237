import React from "react";
import { TheContentApplicant, TheFooter, TheHeaderApplicant, TheSidebarApplicant } from "./index";

const TheLayoutApplicant = () => {
  return (
    <div className="c-app c-default-layout">
      <TheSidebarApplicant />
      <div className="c-wrapper">
        <TheHeaderApplicant />
        <div className="c-body">
          <TheContentApplicant />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default TheLayoutApplicant;
