import React from "react";
import { TheContentAdmin, TheFooter, TheHeaderAdmin, TheSidebarAdmin } from "./index";

const TheLayoutAdmin = () => {
  return (
    <div className="c-app c-default-layout">
      <TheSidebarAdmin />
      <div className="c-wrapper">
        <TheHeaderAdmin />
        <div className="c-body">
          <TheContentAdmin />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default TheLayoutAdmin;
