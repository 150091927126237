import { useMutation } from "@apollo/client";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSelect,
  CTextarea
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { SAVE_APPLICATION_PART2 } from "../../../../graphql/mutations";
import { notifyErrorToast } from "../../../utils/misc";

const EmploymentEducationalDetails = ({
  isDisabled,
  onStepChange,
  applicationData,
}) => {
  const [saveApplicationPart2, { data, loading, called }] = useMutation(
    SAVE_APPLICATION_PART2
  );

  const [isErrors, setIsErrors] = useState(false);

  const [presentEmployment, setPresentEmployment] = useState({
    designation: "",
    address: "",
    name: "",
  });

  const handleOnChangePresentEmployment = (e) => {
    const { value, name } = e.target;
    setPresentEmployment((presentEmployment) => ({
      ...presentEmployment,
      [name]: value,
    }));
  };

  const [employerDetails, setEmployerDetails] = useState([
    {
      name: "",
      position: "",
      nature: "",
      from: "",
      to: "",
      address: "",
    },
  ]);

  useEffect(() => {
    if (applicationData) {
      setPresentEmployment({
        designation: applicationData.employmentPresentDetails.designation,
        address: applicationData.employmentPresentDetails.address,
        name: applicationData.employmentPresentDetails.name,
      });

      if(applicationData.employmentDetails){
        setEmployerDetails(applicationData.employmentDetails);
      }

      setEduDetails(applicationData.educationDetails);
      setPubDetails(applicationData.publicationDetails);
    }
  }, [applicationData]);

  const addEmployment = (data) => {
    //console.log("Employment Added");
    let temp_emp = {
      name: data.name,
      position: data.position,
      nature: data.nature,
      from: data.from,
      to: data.to,
      address: data.address,
    };
    setEmployerDetails((employerDetails) => [...employerDetails, temp_emp]);
  };

  const removeEmployment = (index) => {
    setEmployerDetails((employerDetails) =>
      // Filter out the item with the matching index
      employerDetails.filter((value, i) => i !== index)
    );
  };

  const handleOnChangeEmployment = (e) => {
    const { value, name } = e.target;

    let [r_name, index] = name.split("_");

    let temp_array = [...employerDetails];
    temp_array[index][r_name] = value;
    setEmployerDetails(temp_array);
    // validate();
  };

  const uniList = [
    "University of Peradeniya",
    "University of Sri Jayewardenepura",
    "University of Jaffna",
    "University of Ruhuna",
    "University of Moratuwa",
    "South Eastern University of Sri Lanka",
  ];
  const [eduDetails, setEduDetails] = useState([
    {
      university: "University of Peradeniya",
      degree: "",
      field: "",
      from: "",
      to: "",
      class: "",
    },
  ]);

  const addEducation = (data) => {
    let temp_edu = {
      university: data.university,
      degree: data.degree,
      field: data.field,
      from: data.from,
      to: data.to,
      class: data.class,
    };
    setEduDetails((eduDetails) => [...eduDetails, temp_edu]);
    setIsEduInvalid((isEduInvalid) => [...isEduInvalid, true]);
  };

  const removeEducation = (index) => {
    // console.log("PPP")
    setEduDetails((eduDetails) =>
      // Filter out the item with the matching index
      eduDetails.filter((value, i) => i !== index)
    );
    let temp_invalid = isEduInvalid;
    temp_invalid.pop();
    setIsEduInvalid(temp_invalid);
  };

  const [pubDetails, setPubDetails] = useState([
    {
      name: "",
    },
  ]);

  const addPublication = (pub_name) => {
    let temp_pub = {
      name: pub_name,
    };
    setPubDetails((pubDetails) => [...pubDetails, temp_pub]);
  };

  const removePublication = (index) => {
    // console.log("PPP")
    setPubDetails((pubDetails) =>
      // Filter out the item with the matching index
      pubDetails.filter((value, i) => i !== index)
    );
  };

  const handleOnChangePublication = (e) => {
    const { value, name } = e.target;

    let [r_name, index] = name.split("_");
    // console.log(r_name, index);
    let temp_array = [...pubDetails];
    temp_array[index][r_name] = value;
    setPubDetails(temp_array);
  };

  const handleNext = () => {
    validate();
    // console.log(presentEmployment);
    // console.log(employerDetails);
    // console.log(eduDetails);
    // console.log(pubDetails);
    let proceed_flag = true;
    let i;
    if (isEduInvalid.length < 3) {
      proceed_flag = false;
    } else {
      for (i = 0; i < isEduInvalid.length; i++) {
        if (isEduInvalid[i] === true) {
          proceed_flag = false;
        }
      }
    }
    if (proceed_flag) {
      saveApplicationPart2({
        variables: {
          emp_present_details: presentEmployment,
          emp_details: employerDetails,
          edu_details: eduDetails,
          publications: pubDetails,
        },
      })
        .then((data) => {
          onStepChange(3);
        })
        .catch((err) => notifyErrorToast(err.message));
    }
  };

  const handleOnChangeEducation = (e) => {
    const { value, name } = e.target;

    let [r_name, index] = name.split("_");
    //console.log(r_name, index);
    let temp_array = [...eduDetails];
    temp_array[index][r_name] = value;
    setEduDetails(temp_array);
    validate();
  };

  const [isEduInvalid, setIsEduInvalid] = useState([]);

  const validate = () => {
    let index = 0;
    eduDetails.forEach((num) => {
      //console.log("In num", eduDetails);
      let flag = false;
      for (const [key, value] of Object.entries(num)) {
        if (value.replace(/ /g, "").length === 0) {
          flag = true;

          break;
        } else {
          flag = false;
        }
        if (key === "university" && value === "Other") {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      let temp_arr = isEduInvalid;
      temp_arr[index] = flag;
      index++;
      setIsEduInvalid(temp_arr);
    });

    for (let key in isEduInvalid) {
      if (isEduInvalid.hasOwnProperty(key)) {
        //console.log(isEduInvalid)
        if (isEduInvalid[key] === true) {
          setIsErrors(true);
          break;
        } else {
          setIsErrors(false);
        }
      }
    }
  };

  return (
    <>
      <CCard className="custom-card">
        <CCardHeader className="custom-card-header">
          Employment & Educational Details
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="presentdesignation" className="h6">
                  Present Employment (Designation)
                </CLabel>
                <CInput
                  id="designation"
                  name="designation"
                  onChange={handleOnChangePresentEmployment}
                 
                  disabled={isDisabled}
                  value={presentEmployment.designation}
                />
              </CFormGroup>

              <CFormGroup>
                <CLabel htmlFor="presentempname" className="h6">
                  Name of Employer
                </CLabel>
                <CInput
                  id="name"
                  name="name"
                  onChange={handleOnChangePresentEmployment}
                 
                  disabled={isDisabled}
                  value={presentEmployment.name}
                />
              </CFormGroup>
            </CCol>

            <CCol xs="12" md="6" xl="6">
              <CFormGroup>
                <CLabel htmlFor="textarea-input-home" className="h6">
                  Address of Employer
                </CLabel>
                <CTextarea
                  id="address"
                  name="address"
                  onChange={handleOnChangePresentEmployment}
                  rows="5"
                 
                  disabled={isDisabled}
                  value={presentEmployment.address}
                />
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs="12" md="12" xl="12" className="mt-3">
              <CFormGroup>
                <span className="h6">
                  Employment Record (Include the present position as well)
                </span>
              </CFormGroup>
            </CCol>
          </CRow>
          {employerDetails.map((value, idx) => {
            return (
              <CRow
                style={{
                  padding: 15,
                  marginLeft: 2,
                  marginRight: 3,
                  border: `2px solid #d8dbe0`,
                  borderRadius: 15,
                }}
                className="mb-5"
                id={idx + "x"}
              >
                <CCol xs="12" md="6" xl="6">
                  <CFormGroup>
                    <CLabel htmlFor="nameemp" className="h6">
                      Name of Employer
                    </CLabel>
                    <CInput
                      id={"name_" + idx}
                      name={"name_" + idx}
                      onChange={handleOnChangeEmployment}
                      
                      disabled={isDisabled}
                      value={employerDetails[idx].name}
                    />
                  </CFormGroup>

                  <CFormGroup>
                    <CLabel htmlFor="position" className="h6">
                      Position / Rank
                    </CLabel>
                    <CInput
                      id={"position_" + idx}
                      name={"position_" + idx}
                      onChange={handleOnChangeEmployment}
                     
                      disabled={isDisabled}
                      value={employerDetails[idx].position}
                    />
                  </CFormGroup>

                  <CFormGroup>
                    <CLabel htmlFor="natureofduty" className="h6">
                      Nature of Duty
                    </CLabel>
                    <CInput
                      id={"nature_" + idx}
                      name={"nature_" + idx}
                      onChange={handleOnChangeEmployment}
                      
                      disabled={isDisabled}
                      value={employerDetails[idx].nature}
                    />
                  </CFormGroup>

                  <CFormGroup>
                    <CLabel htmlFor="home-tp" className="h6">
                      From
                    </CLabel>
                    <CInput
                      type="date"
                      id={"from_" + idx}
                      name={"from_" + idx}
                      onChange={handleOnChangeEmployment}
                      placeholder="date"
                      disabled={isDisabled}
                      value={employerDetails[idx].from}
                    />
                  </CFormGroup>
                  {!isDisabled ? (
                    <CButton
                      type="submit"
                      size="md"
                      color="primary"
                      onClick={() =>
                        addEmployment({
                          name: "",
                          position: "",
                          nature: "",
                          from: "",
                          to: "",
                          address: "",
                        })
                      }
                      disabled={isDisabled}
                    >
                      Add New&nbsp;
                      <CIcon name="cil-plus" />
                    </CButton>
                  ) : (
                    <></>
                  )}
                  {idx !== 0 && !isDisabled ? (
                    <CButton
                      type="submit"
                      size="md"
                      color="danger"
                      className="ml-2"
                      onClick={() => removeEmployment(idx)}
                      disabled={isDisabled}
                    >
                      Remove&nbsp;&nbsp;
                      <CIcon name="cil-minus" />{" "}
                    </CButton>
                  ) : (
                    <></>
                  )}
                </CCol>

                <CCol xs="12" md="6" xl="6">
                  <CFormGroup>
                    <CLabel htmlFor="textarea-input-home" className="h6">
                      Address of Employer
                    </CLabel>
                    <CTextarea
                      id={"address_" + idx}
                      name={"address_" + idx}
                      onChange={handleOnChangeEmployment}
                      rows="9"
                      
                      disabled={isDisabled}
                      value={employerDetails[idx].address}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="home-tp" className="h6">
                      To
                    </CLabel>
                    <CInput
                      type="date"
                      id={"to_" + idx}
                      name={"to_" + idx}
                      onChange={handleOnChangeEmployment}
                      placeholder="date"
                      disabled={isDisabled}
                      value={employerDetails[idx].to}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
            );
          })}
          <CRow>
            <CCol xs="12" md="12" xl="12">
              <CFormGroup>
                <span className="h6">
                  Educational / Professional Qualifications :
                </span>
              </CFormGroup>
            </CCol>
          </CRow>
          {eduDetails.map((val, id) => {
            return (
              <CRow
                style={{
                  padding: 15,
                  marginLeft: 2,
                  marginRight: 3,
                  border: `2px solid #d8dbe0`,
                  borderRadius: 15,
                }}
                className="mb-5"
                id={id + "e"}
              >
                <CCol xs="12" md="6" xl="6">
                  <CRow>
                    <CCol
                      xs="12"
                      md={
                        !uniList.includes(eduDetails[id].university)
                          ? "6"
                          : "12"
                      }
                      xl={
                        !uniList.includes(eduDetails[id].university)
                          ? "6"
                          : "12"
                      }
                    >
                      <CFormGroup>
                        <CLabel htmlFor="university" className="h6">
                          University or Institute
                        </CLabel>
                        <span style={{ color: "#ab0202" }}> *</span>
                        <CSelect
                          custom
                          name={"university_" + id}
                          id={"university_" + id}
                          onChange={handleOnChangeEducation}
                          disabled={isDisabled}
                          value={
                            uniList.includes(eduDetails[id].university)
                              ? eduDetails[id].university
                              : "Other"
                          }
                        >
                          <option value="University of Peradeniya">
                            University of Peradeniya
                          </option>
                          <option value="University of Sri Jayewardenepura">
                            University of Sri Jayewardenepura
                          </option>
                          <option value="University of Jaffna">
                            University of Jaffna
                          </option>
                          <option value="University of Ruhuna">
                            University of Ruhuna
                          </option>
                          <option value="University of Moratuwa">
                            University of Moratuwa
                          </option>
                          <option value="South Eastern University of Sri Lanka">
                            South Eastern University of Sri Lanka
                          </option>
                          <option value="Other">Other</option>
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                    {!uniList.includes(eduDetails[id].university) && (
                      <CCol xs="12" md="6" xl="6">
                        <CFormGroup>
                          <CLabel htmlFor={"university_" + id} className="h6">
                            Other
                          </CLabel>
                          <span style={{ color: "#ab0202" }}> *</span>

                          <CInput
                            id={"university_" + id}
                            
                            name={"university_" + id}
                            onChange={handleOnChangeEducation}
                            disabled={isDisabled}
                            value={eduDetails[id].university}
                          />
                        </CFormGroup>
                      </CCol>
                    )}
                  </CRow>

                  <CFormGroup>
                    <CLabel htmlFor="fromuni" className="h6">
                      From
                    </CLabel>
                    <span style={{ color: "#ab0202" }}> *</span>
                    <CInput
                      type="date"
                      id={"from_" + id}
                      name={"from_" + id}
                      
                      onChange={handleOnChangeEducation}
                      disabled={isDisabled}
                      value={eduDetails[id].from}
                    />
                  </CFormGroup>

                  <CFormGroup>
                    <CLabel htmlFor="field" className="h6">
                      Field
                    </CLabel>
                    <span style={{ color: "#ab0202" }}> *</span>
                    <CInput
                     
                      id={"field_" + id}
                      name={"field_" + id}
                      onChange={handleOnChangeEducation}
                      disabled={isDisabled}
                      value={eduDetails[id].to}
                    />
                  </CFormGroup>
                  <div
                    className={
                      isEduInvalid[id] ? "small mb-2" : "d-sm-none mb-2"
                    }
                    style={{ color: "#ab0202" }}
                  >
                    * Please fill out all compulsory fields!
                  </div>
                  {!isDisabled ? (
                    <CButton
                      type="submit"
                      size="md"
                      color="primary"
                      onClick={() =>
                        addEducation({
                          university: "University of Peradeniya",
                          degree: "",
                          field: "",
                          from: "",
                          to: "",
                          class: "",
                        })
                      }
                    >
                      Add New&nbsp;
                      <CIcon name="cil-plus" />
                    </CButton>
                  ) : (
                    <></>
                  )}
                  {id !== 0 && !isDisabled ? (
                    <CButton
                      type="submit"
                      size="md"
                      color="danger"
                      className="ml-2"
                      onClick={() => removeEducation(id)}
                      disabled={isDisabled}
                    >
                      Remove&nbsp;&nbsp;
                      <CIcon name="cil-minus" />{" "}
                    </CButton>
                  ) : (
                    <></>
                  )}
                </CCol>

                <CCol xs="12" md="6" xl="6">
                  <CFormGroup>
                    <CLabel htmlFor="degree" className="h6">
                      Degree
                    </CLabel>
                    <span style={{ color: "#ab0202" }}> *</span>
                    <CInput
                      id={"degree_" + id}
                      
                      name={"degree_" + id}
                      onChange={handleOnChangeEducation}
                      disabled={isDisabled}
                      value={eduDetails[id].degree}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="home-tp" className="h6">
                      To
                    </CLabel>
                    <span style={{ color: "#ab0202" }}> *</span>
                    <CInput
                      type="date"
                      id={"to_" + id}
                      name={"to_" + id}
                      
                      onChange={handleOnChangeEducation}
                      disabled={isDisabled}
                      value={eduDetails[id].to}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="class" className="h6">
                      Class / Pass
                    </CLabel>
                    <span style={{ color: "#ab0202" }}> *</span>
                    <CInput
                      id={"class_" + id}
                      
                      name={"class_" + id}
                      onChange={handleOnChangeEducation}
                      disabled={isDisabled}
                      value={eduDetails[id].class}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
            );
          })}
          <CRow>
            <CCol xs="12" md="12" xl="12">
              <CFormGroup>
                <span className="h6">List of publications :</span>
              </CFormGroup>
            </CCol>
          </CRow>
          {pubDetails.map((pub, ids) => {
            return (
              <CRow
                style={{
                  padding: 15,
                  marginLeft: 2,
                  marginRight: 3,
                  border: `2px solid #d8dbe0`,
                  borderRadius: 15,
                }}
                className="mb-5"
                id={ids}
              >
                <CCol xs="12" md="12" xl="12">
                  <CFormGroup>
                    <CLabel htmlFor="presentdesignation" className="h6">
                      Publication Name
                    </CLabel>
                    <CInput
                      id={"name_" + ids}
                      onChange={handleOnChangePublication}
                      name={"name_" + ids}
                      
                      disabled={isDisabled}
                      value={pubDetails[ids].name}
                    />
                  </CFormGroup>

                  {!isDisabled ? (
                    <CButton
                      type="submit"
                      size="md"
                      color="primary"
                      onClick={() => addPublication("" + ids)}
                      disabled={isDisabled}
                    >
                      Add New&nbsp;
                      <CIcon name="cil-plus" />
                    </CButton>
                  ) : (
                    <></>
                  )}
                  {ids !== 0 ? (
                    <CButton
                      type="submit"
                      size="md"
                      color="danger"
                      className="ml-2"
                      onClick={() => removePublication(ids)}
                      disabled={isDisabled}
                    >
                      Remove&nbsp;&nbsp;
                      <CIcon name="cil-minus" />{" "}
                    </CButton>
                  ) : (
                    <></>
                  )}
                </CCol>
              </CRow>
            );
          })}
          <div
            className={isErrors ? "small mb-2" : "d-sm-none mb-2"}
            style={{ color: "#ab0202" }}
          >
            * Please fill out all compulsory fields!
          </div>
          <hr />
          <CRow>
            <CCol xs="12" md="12" xl="12" className="d-flex flex-row-reverse">
              {isDisabled ? (
                <CButton
                  className="ml-4"
                  type="submit"
                  size="md"
                  color="primary"
                  onClick={() => onStepChange(3)}
                  disabled={loading}
                >
                  Next&nbsp;&nbsp;
                  <CIcon name="cil-arrow-right" />{" "}
                </CButton>
              ) : (
                <CButton
                  className="ml-4"
                  type="submit"
                  size="md"
                  color="primary"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Next&nbsp;&nbsp;
                  <CIcon name="cil-arrow-right" />{" "}
                </CButton>
              )}
              <CButton
                type="submit"
                size="md"
                color="primary"
                onClick={() => {
                  onStepChange(1);
                }}
              >
                <CIcon name="cil-arrow-left" /> &nbsp;&nbsp;Back
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

export default EmploymentEducationalDetails;
