import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle
} from "@coreui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// sidebar nav config
import navigation from "./_navSuperAdmin";

const TheSidebarAdmin = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow);

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
      className="sidebar  c-sidebar-light"
      style={{ borderRight: "1px solid rgba(0, 0, 0, 0.2)" }}
    >
      <CSidebarBrand
        className="d-md-down-none"
        to="/"
        style={{
          textDecoration: "none",
          backgroundColor: "white",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
        }}
      >
        <img src="../../logo.png" height="30" />
        <span
          style={{
            fontSize: 12,
            marginLeft: 10,
            fontWeight: "400",
            color: "black",
          }}
        >
          Department of Civil Engineering
        </span>
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(TheSidebarAdmin);
