import React from "react";
import ViewApplication from "./views/coordinator/view-application/ViewApplication";

const DashboardAdmin = React.lazy(
  () => import("./views/superAdmin/dashboard/Dashboard")
);
const CoordinatorsAdmin = React.lazy(
  () => import("./views/superAdmin/coordinators/Coordinators")
);

const DeadlineAdmin = React.lazy(
  () => import("./views/superAdmin/deadline/Deadline")
);
const Profile = React.lazy(() => import("./views/profile/Profile"));

const routesAdmin = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: DashboardAdmin },
  { path: "/profile", name: "Profile", component: Profile },
  { path: "/coordinators", name: "Coordinators/Admins", component: CoordinatorsAdmin },
  { path: "/deadlines", name: "Deadline", component: DeadlineAdmin },
  {
    path: "/viewApplication",
    name: "View Application",
    component: ViewApplication,
  },
];

export default routesAdmin;
